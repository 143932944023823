import React, { Component, forwardRef } from "react";
import performRequest from "../../../network/perform-request";
import { competitionsReorderTournamentsRoute, competitionsTournamentsRoute } from "../../../network/api-routes";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import EmptyPage from "../../common/EmptyPage";
import { Link } from "react-router-dom";
import "moment/locale/es";
import CompetitionTournament from "components/common/CompetitionTournament";
import SortableList from "components/common/SortableList";
import { withSnackbar } from "notistack";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  container: {
    padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
  },
  tournamentsContainer: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    marginTop: 30,
  },
  title: {
    fontWeight: 400,
    fontSize: 48,
    color: theme.palette.text.primary,
  },
  createButton: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    fontSize: 20,
    fontWeight: 600,
    textTransform: "none",
  },
  chosenClass: {
    backgroundColor: "black",
  },
});

const CustomComponent = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{
      display: "grid",
      gridTemplateColumns: "50% 50%",
      width: "100%",
      rowGap: 12,
      columnGap: 12,
    }}
  >
    {props.children}
  </div>
));

class CompetitionTournaments extends Component {
  constructor(props) {
    super();

    this.state = {
      id: props.match.params.id,
      ready: false,
      tournaments: [],
      selectedIndex: 0,
      newTournamentsIdsOrder: null,
      oldTournamentsIdsOrder: null,
      reorderDisabled: true,
      reorderReady: true,
    };
  }

  componentDidMount() {
    this.fetch(this.props.userContext.user);
  }

  fetch(user) {
    performRequest("POST", competitionsTournamentsRoute, { competition_uuid: this.state.id }, true, user)
      .then((response) => {
        const tournaments = response.data.data;
        const oldTournamentsIdsOrder = tournaments.map((tournament) => tournament.id.toString());
        this.setState({
          tournaments,
          oldTournamentsIdsOrder,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  reorderTournaments() {
    const { oldTournamentsIdsOrder, newTournamentsIdsOrder } = this.state;
    console.log(oldTournamentsIdsOrder, newTournamentsIdsOrder);

    if (JSON.stringify(oldTournamentsIdsOrder) === JSON.stringify(newTournamentsIdsOrder)) {
      return null;
    }

    this.setState({ reorderReady: false }, () =>
      performRequest(
        "POST",
        competitionsReorderTournamentsRoute,
        { competition_uuid: this.state.id, order: newTournamentsIdsOrder },
        true,
        this.props.userContext.user
      )
        .then((response) => {
          console.log(response);
          this.setState(
            {
              tournamentsIds: null,
              oldTournamentsIdsOrder: newTournamentsIdsOrder,
              reorderReady: true,
            },
            () =>
              this.props.enqueueSnackbar("Torneos reordenados con exito", {
                variant: "success",
              })
          );
        })
        .catch((error) => {
          this.setState({ reorderReady: true }, () =>
            this.props.enqueueSnackbar("No se pueden reordenar los torneos", {
              variant: "error",
            })
          );
        })
    );
  }

  render() {
    const { classes } = this.props;
    const { tournaments, reorderDisabled, reorderReady } = this.state;

    return (
      <Grid container>
        <Grid className={classes.container} container item xs={12} justifyContent="space-between">
          <Typography variant="h1" className={classes.title}>
            Torneos
          </Typography>
          <Grid>
            {!reorderDisabled && (
              <Button onClick={() => this.setState({ reorderDisabled: true, tournamentsIds: null })}>Cancelar</Button>
            )}
            <Button
              className={classes.createButton}
              disabled={!reorderReady}
              onClick={() => {
                if (reorderDisabled) {
                  this.setState({ reorderDisabled: false });
                } else {
                  this.setState({ reorderDisabled: true }, this.reorderTournaments());
                }
              }}
            >
              {reorderDisabled ? "Reordenar categorías" : "Guardar cambios"}
            </Button>
            <Link to={`/evento/${this.state.id}/crear-torneo`}>
              <Button className={`cta ${classes.createButton}`}>Crear un torneo</Button>
            </Link>
          </Grid>
        </Grid>
        {!this.state.ready && (
          <Grid item container justifyContent="center" style={{ marginTop: 30 }}>
            <CircularProgress size={24}></CircularProgress>
          </Grid>
        )}
        {this.state.ready && (
          <Grid container className={classes.tournamentsContainer}>
            {tournaments.length === 0 && <EmptyPage text="¡Crea tu primer torneo para empezar!" />}
            <Grid item xs={12}>
              <SortableList
                renderItem={(tournament) => <CompetitionTournament key={tournament.id} tournament={tournament} />}
                listComponent={CustomComponent}
                disabled={reorderDisabled}
                items={tournaments}
                onChange={(sortedList) => {
                  const newTournamentsIdsOrder = sortedList.map((tournament) => tournament.id.toString());
                  this.setState({ newTournamentsIdsOrder, tournaments: sortedList });
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(CompetitionTournaments));
