import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Grid,
  Typography,
  TableContainer,
  Avatar,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import _ from "lodash";
import { matchesUpdateScoreDetailsRoute } from "../../network/api-routes";
import { performRequest } from "../../network/perform-request";
import withUserContext from "../hoc/withUserContext";
const styles = (theme) => ({
  score: {
    width: 70,
  },
  emptyScoreInput: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
  scoreInputNotchedOutline: {
    borderColor: theme.palette.primary.main,
  },
  emptyScoreInputNotchedOutline: {
    borderColor: theme.palette.background.paper,
  },
  scoreInput: {
    fontSize: 16,
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  teamTableContainer: {
    padding: theme.spacing(10),
  },
  teamName: {
    fontWeight: 600,
    fontSize: "2rem",
  },
});

class MatchScoreDetails extends Component {
  constructor(props) {
    super();
    const { match } = props;
    const player1 = match.player1.data;
    const team1 = player1.team.data;
    const team1Players = team1.players.data;
    const player2 = match.player2.data;
    const team2 = player2.team.data;
    const team2Players = team2.players.data;

    const scoreDetails = match.score_details;
    const standings = scoreDetails["standingsByPlayerId"];

    this.state = {
      player1,
      player2,
      team1,
      team2,
      teamPlayers: [_.sortBy(team1Players, ["id"]), _.sortBy(team2Players, ["id"])],
      standings,
    };

    this.debouncedSavePlayer = _.debounce(this.savePlayer, 250, {
      maxWait: 1000,
    });

    this.debouncedSavePlayer = this.debouncedSavePlayer.bind(this);
  }

  handleChange = (playerId, propertyName) => (event) => {
    let newStandings = this.state.standings;
    console.log("standings", newStandings, "playerID", playerId, "property name", propertyName);

    if (!newStandings[playerId]) {
      newStandings[playerId] = {
        bans: 0,
        goals: 0,
        warnings: 0,
        player_id: playerId,
      };
    }
    newStandings[playerId][propertyName] = parseInt(event.target.value);
    const playerStandings = newStandings[playerId];
    this.setState(
      {
        standings: newStandings,
      },
      () => {
        this.debouncedSavePlayer(playerId, playerStandings);
      }
    );
  };

  savePlayer(playerId, standings) {
    this.setState({
      ready: false,
    });
    const { user } = this.props.userContext;
    performRequest(
      "POST",
      matchesUpdateScoreDetailsRoute,
      {
        match_id: this.props.match.id,
        player_id: playerId,
        standings: standings,
      },
      true,
      user
    )
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  renderTeamTable(participant, players) {
    const { classes } = this.props;

    return <>
      <Grid container justifyContent="center" className={classes.teamTableContainer}>
        <Grid container spacing={4}>
          <Grid item xs={12} container justifyContent="center">
            <Avatar
              alt={participant.name}
              src={participant.full_profile_pic_url}
              style={{ width: 150, height: 150 }}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Typography className={classes.teamName}>{participant.name}</Typography>
          </Grid>
        </Grid>

        <TableContainer style={{ marginTop: 20 }}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center" style={{ fontSize: 10 }}>
                  Goles/Puntos
                </TableCell>
                <TableCell align="center" style={{ fontSize: 10 }}>
                  Amonestaciones 🟥
                </TableCell>
                <TableCell align="center" style={{ fontSize: 10 }}>
                  Advertencias 🟨
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {players.map((player, i) => {
                const standings = this.state.standings[player.id] || [];
                return (
                  <TableRow key={player.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {player.name}
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        className={classes.score}
                        InputProps={{
                          classes: {
                            root:
                              standings && standings["goals"] === 0 ? classes.emptyScoreInput : classes.scoreInput,
                            notchedOutline:
                              standings && standings["goals"] === 0
                                ? classes.emptyScoreInputNotchedOutline
                                : classes.scoreInputNotchedOutline,
                          },
                        }}
                        margin="normal"
                        type="number"
                        inputProps={{ min: 0 }}
                        variant="outlined"
                        defaultValue={standings["goals"] || 0}
                        onChange={this.handleChange(player.id, "goals")}
                        size="small"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        className={classes.score}
                        InputProps={{
                          classes: {
                            root: standings && standings["bans"] === 0 ? classes.emptyScoreInput : classes.scoreInput,
                            notchedOutline:
                              standings && standings["bans"] === 0
                                ? classes.emptyScoreInputNotchedOutline
                                : classes.scoreInputNotchedOutline,
                          },
                        }}
                        margin="normal"
                        type="number"
                        inputProps={{ min: 0 }}
                        variant="outlined"
                        defaultValue={standings["bans"] || 0}
                        onChange={this.handleChange(player.id, "bans")}
                        size="small"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        className={classes.score}
                        InputProps={{
                          classes: {
                            root:
                              standings && standings["warnings"] === 0 ? classes.emptyScoreInput : classes.scoreInput,
                            notchedOutline:
                              standings && standings["warnings"] === 0
                                ? classes.emptyScoreInputNotchedOutline
                                : classes.scoreInputNotchedOutline,
                          },
                        }}
                        margin="normal"
                        type="number"
                        inputProps={{ min: 0 }}
                        variant="outlined"
                        defaultValue={standings["warnings"] || 0}
                        onChange={this.handleChange(player.id, "warnings")}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>;
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item md={6}>
          {this.renderTeamTable(this.state.player1, this.state.teamPlayers[0])}
        </Grid>

        <Grid item md={6}>
          {this.renderTeamTable(this.state.player2, this.state.teamPlayers[1])}
        </Grid>
      </Grid>
    );
  }
}

export default withUserContext(withStyles(styles)(MatchScoreDetails));
