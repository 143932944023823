import React from 'react';
const Icon = props => {
  return (
    <i
      className={`icon-${props.slug}`}
      style={{
        color: props.color || '#231815',
        fontSize: props.size || 'inherit',
        ...props.style
      }}
    />
  );
};

export default Icon;
