import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Typography, TableContainer, IconButton, Link, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";

import Paper from "@mui/material/Paper";
import { withSnackbar } from "notistack";
import performRequest from "network/perform-request";
import { punctuationIndexRoute } from "network/api-routes";

const styles = (theme) => ({
  container: {
    padding: `${theme.spacing(2)} ${theme.spacing(12)}`,
    // minHeight: "100vh",
    overflowX: "auto",
  },
  headerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  headerText: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  tableContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  createButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(-1.5),
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
  },
  sectionContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    marginBottom: theme.spacing(8),
    boxShadow: "none",
    width: "100%",
  },
  sectionTitleContainer: {
    backgroundColor: theme.palette.action.hover,
    height: 46,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    "&$expanded": {
      maxHeight: 46,
    },
  },

  sectionTitle: {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 14,
  },
});

const PunctuationIndex = (props) => {
  //a component that shows a list of punctuation rules. Retrieves from server and shows them.
  //it also has a search bar to filter the list and a button to create a new one

  const { classes, userContext, enqueueSnackbar } = props;
  const [ready, setReady] = useState(false);
  const [punctuationList, setPunctuationList] = useState(null);

  //on mount, fetch the list of tournaments
  useEffect(() => {
    performRequest("POST", punctuationIndexRoute, {}, true, userContext.user ? userContext.user : null)
      .then((response) => {
        if (response.data) {
          setPunctuationList(response.data);
          setReady(true);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.message, { variant: "error" });
        setReady(true);
      });
  }, [enqueueSnackbar, userContext.user]);

  if (!ready)
    return (
      <Grid container className={classes.container} justifyContent="center">
        <CircularProgress size={24} />
      </Grid>
    );

  return (
    <Grid container className={props.containerStyle || classes.container}>
      <Paper className={classes.sectionContainer}>
        <Grid container>
          <Grid container item xs={12} justifyContent="space-between" className={classes.sectionTitleContainer}>
            <Grid item container xs={4}>
              <Typography variant="h1" className={classes.sectionTitle}>
                {"Sistemas de puntuación"}
              </Typography>
            </Grid>
            <Grid item container xs={8} justifyContent="flex-end">
              <Link href="/create-punctuation" underline="hover">
                <Button className={`cta ${classes.createButton}`}>Crear nuevo sistema</Button>
              </Link>
            </Grid>
          </Grid>

          <Grid container item xs={12} lg={12} justifyContent="center" className={classes.tableContainer}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nombre</TableCell>
                    <TableCell align="center">Descripción</TableCell>
                    <TableCell align="center">Organización</TableCell>
                    <TableCell align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {punctuationList
                    ? punctuationList.map((punctuation) => (
                        <TableRow key={punctuation.id}>
                          <TableCell align="center" component="th" scope="row">
                            {punctuation.name}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {punctuation.description}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {punctuation?.organization?.name ?? "Ninguna"}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            <IconButton
                              aria-label="edit"
                              size="small"
                              onClick={() => {
                                props.history.push(`/punctuation/${punctuation.uuid_}`);
                              }}
                              className={classes.editButtonContainer}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default withSnackbar(withStyles(styles)(PunctuationIndex));
