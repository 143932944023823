import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { Drawer, List, ListItem, ListItemText, ListItemIcon, AppBar, Toolbar } from "@mui/material";
import { Link, withRouter } from "react-router-dom";
import Icon from "../common/Icon";
import UserContext from "../../context/UserContext";

import SignIn from "../pages/SignIn/SignInForm";
import Register from "../pages/Register/RegisterForm";

import MenuButton from "./MenuButton";
import LinksTopBar from "./LinksTopBar";
import ResponsiveDialog from "../common/ResponsiveDialog";
import { logoutUser } from "../../network/user";

const styles = (theme) => ({
  root: {
    top: 0,
    zIndex: 10,
    position: "sticky",
    border: "1px solid whitesmoke",
    borderWidth: "0 0 1px 0",
    padding: "10px 5px",
    background: "rgba(255,255,255,0.8)",
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  toolbar: {
    color: theme.palette.text.primary,
  },
  logo: {
    height: "30px",
  },
  menuButton: {
    alignItems: "center",
    color: "#767676",
    padding: theme.spacing(2),
  },
});

const links = () => {
  return [
    {
      auth: true,
      iconSlug: "file",
      url: "/mis-reservas",
      title: "Mis reservas",
    },
    {
      auth: true,
      iconSlug: "star_o",
      url: "/favoritos",
      title: "Favoritos",
    },
  ];
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      signInModalOpen: false,
      registerModalOpen: false,
      loggedOut: false,
    };
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
  }

  toggleDrawer = (open) => () => {
    this.setState({
      drawerOpen: open,
    });
  };

  handleSignInOpen = (open) => {
    this.setState({
      signInModalOpen: open,
    });
  };

  handleRegisterOpen = (open) => {
    this.setState({
      registerModalOpen: open,
    });
  };

  handleLogoutClick(userContext) {
    logoutUser(userContext.changeUser);
  }

  renderMenuButton() {
    return <MenuButton {...this.props} toggleDrawer={this.toggleDrawer} />;
  }

  renderLinks(userContext) {
    return (
      <LinksTopBar
        userContext={userContext}
        handleSignInOpen={this.handleSignInOpen}
        handleRegisterOpen={this.handleRegisterOpen}
        handleLogoutClick={this.handleLogoutClick}
        links={links}
      />
    );
  }

  renderDrawer(userContext) {
    const { user } = userContext;
    return (
      <Drawer
        sx={{ display: { md: "none", xs: "block" } }}
        anchor="top"
        open={this.state.drawerOpen}
        onClose={this.toggleDrawer(false)}
      >
        <div tabIndex={0} role="button" onClick={this.toggleDrawer(false)} onKeyDown={this.toggleDrawer(false)}>
          <List>
            <Link to="/">
              <ListItem>
                <ListItemText primary="Inicio" />
                <ListItemIcon>
                  <Icon slug="home" />
                </ListItemIcon>
              </ListItem>
            </Link>

            {!user && (
              <ListItem
                onClick={() => {
                  this.handleSignInOpen(true);
                }}
              >
                <ListItemText primary="Iniciar sesión" />
              </ListItem>
            )}
            {/* {!user && (
                <ListItem
                  onClick={() => {
                    this.handleRegisterOpen(true);
                  }}
                >
                  <ListItemText primary="Registrarse" />
                </ListItem>
              )} */}
            {user && (
              <Link to="/perfil">
                <ListItem>
                  <ListItemText primary="Perfil" />
                  <ListItemIcon>
                    <Icon slug="user" />
                  </ListItemIcon>
                </ListItem>
              </Link>
            )}
            {user && (
              <ListItem
                onClick={() => {
                  this.handleLogoutClick(userContext);
                }}
              >
                <ListItemText primary="Salir" />
              </ListItem>
            )}
          </List>
        </div>
      </Drawer>
    );
  }

  renderSignInDialog() {
    return (
      <ResponsiveDialog
        onClose={() => {
          this.handleSignInOpen(false);
        }}
        open={this.state.signInModalOpen}
        dialogTitle="Iniciar sesión"
      >
        <SignIn
          successCallback={() => {
            this.handleSignInOpen(false);
          }}
        />
      </ResponsiveDialog>
    );
  }

  renderRegisterDialog() {
    return (
      <ResponsiveDialog
        onClose={() => {
          this.handleRegisterOpen(false);
        }}
        open={this.state.registerModalOpen}
      >
        <Register
          successCallback={() => {
            this.handleRegisterOpen(false);
          }}
          openSignIn={() => {
            this.handleRegisterOpen(false);
            this.handleSignInOpen(true);
          }}
        />
      </ResponsiveDialog>
    );
  }

  render() {
    const hideHeader =
      this.props.history.location.pathname === "/landing" || this.props.history.location.pathname === "/entrar";
    if (hideHeader) {
      return null;
    }
    return (
      <>
        <UserContext.Consumer>
          {(userContext) => (
            <AppBar position="static" style={{ background: "#28282a" }}>
              <Toolbar>
                {this.renderMenuButton()}

                {this.renderLinks(userContext)}
                {/* <IconButton
                  edge="end"
                  aria-label="enable dark mode"
                  aria-haspopup="false"
                  onClick={this.props.handleThemeChange}
                  color="inherit"
                >
                  <Brightness4Icon />
                </IconButton> */}
                {this.renderDrawer(userContext)}

                {this.renderSignInDialog()}
                {this.renderRegisterDialog()}
              </Toolbar>
            </AppBar>
          )}
        </UserContext.Consumer>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(Header));
