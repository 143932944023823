import React, { Component } from "react";
import { Grid, Paper, CircularProgress, Typography, Dialog } from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import performRequest from "../../../network/perform-request";
import { competitionsGetSignUpsRoute } from "../../../network/api-routes";
import { DataGrid } from "@mui/x-data-grid";
import EditSignUpModal from "./EditSignUpModal";

const styles = (theme) => ({
  table: {
    border: `1px solid ${theme.palette.divider}`,
  },
  roundHeader: {
    fontSize: 16,
    fontWeight: 700,
  },
  firstColumnCell: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
});

const columns = [
  { field: "createdAt", headerName: "Fecha", flex: 1 },
  {
    field: "participant",
    headerName: "Participante",
    flex: 1,
  },
  {
    field: "tournament",
    headerName: "Torneo",
    flex: 1,
  },
  {
    field: "type",
    headerName: "Origen",
    flex: 1,
  },

  {
    field: "paymentType",
    headerName: "Tipo de pago",
    flex: 1,
  },
  {
    field: "paymentStatus",
    headerName: "Estado de pago",
    flex: 1,
  },
];

class CompetitionSignUps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      signUps: [],
      rows: [],
      selectedRow: null,
      openSignUpModal: false,
    };

    this.onRowUpdated = this.onRowUpdated.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  parseRows(signUps) {
    const rows = signUps.map((signUp) => {
      let paymentText = null;
      switch (signUp.status) {
        case "waiting-payment":
          paymentText = "⚠ Esperando pago";
          break;
        case "expired":
          paymentText = "✖ Expirado";
          break;
        default:
          paymentText = "✔ Pagado";
          break;
      }

      return {
        id: signUp.id,
        createdAt: moment(signUp.created_at).format("ddd DD/MM HH:mm"),
        participant: signUp.participant ? signUp.participant.data.display_name : signUp.user.full_name,
        tournament: signUp.tournament.data.name,
        type: signUp.is_admin ? "Manual" : "Online",
        paymentType: signUp.payment_id ? "Pago Online" : "Efectivo / Otro",
        paymentStatus: paymentText,
        profiles: "Ver perfiles",
      };
    });

    return rows;
  }

  fetch() {
    const { user } = this.props;
    performRequest("POST", competitionsGetSignUpsRoute, { competition_uuid: this.props.competition.uuid_ }, true, user)
      .then((response) => {
        const signUps = response.data.data;
        const rows = this.parseRows(signUps);
        this.setState({
          signUps,
          rows,
          ready: true,
        });
      })
      .catch((error) => {
        this.setState({
          ready: true,
        });
        console.log(error);
      });
  }

  onRowSelected(row) {
    console.log("row selected is", row);
    this.setState({
      selectedRow: row,
      openSignUpModal: true,
    });
  }

  onRowUpdated(row) {
    this.setState(
      {
        selectedRow: null,
        openSignUpModal: false,
      },
      () => {
        this.fetch();
        this.props.onUpdate();
      }
    );
  }

  renderTournamentSignUpDialog() {
    if (!this.state.selectedRow) {
      return null;
    }
    return (
      <Dialog
        open={this.state.openSignUpModal}
        onClose={() => this.setState({ openSignUpModal: false })}
        disableAutoFocus
        fullWidth={true}
        maxWidth="md"
      >
        <EditSignUpModal
          onSave={this.onRowUpdated}
          onViewProfiles={() => {
            this.onViewProfiles();
          }}
          signUpId={this.state.selectedRow.id}
        />
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    if (!this.state.ready) {
      return (
        <Grid container spacing={2} className={classes.container} justifyContent="center">
          <CircularProgress size={16}></CircularProgress>
          <small>
            <i>Cargando inscripciones...</i>
          </small>
        </Grid>
      );
    }

    return (
      <>
        <Paper style={{ padding: 20 }}>
          <Typography variant="h1" style={{ marginBottom: 20, marginTop: 10 }}>
            Inscripciones
          </Typography>
          {this.state.rows.length > 0 && (
            <DataGrid
              style={{ cursor: "pointer" }}
              onRowClick={({ row }) => this.onRowSelected(row)}
              rows={this.state.rows}
              disableColumnMenu
              hideFooterSelectedRowCount
              columns={columns}
              pageSizeOptions={[10, 25, 50]}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
            />
          )}
          {this.state.rows.length === 0 && (
            <Grid item xs={12} container alignItems="center">
              <Typography variant="caption">Aún no hay inscripciones, ¿ya creaste un torneo?</Typography>
            </Grid>
          )}
        </Paper>
        {this.renderTournamentSignUpDialog()}
      </>
    );
  }
}

export default withStyles(styles)(CompetitionSignUps);
