import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, Dialog, AppBar, Toolbar, IconButton, Paper, useTheme, useMediaQuery } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
    background: "transparent",
    boxShadow: "none",
  },
  flex: {
    flex: 1,
  },
  body: {
    overflowY: "auto",
    height: "auto !important",
    maxHeight: "calc(100vh - 50px) !important",
    [theme.breakpoints.down("md")]: {
      maxHeight: "calc(100vh - 50px) !important",
    },
  },
  footer: {
    padding: theme.spacing(2),
    backgroundColor: "white",
    width: "100%",
    bottom: 0,
    zIndex: 3,
  },
});

const ResponsiveDialog = (props) => {
  const { classes, open, onClose, dialogTitle, footer, children } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose} fullWidth={true} maxWidth="md">
        <AppBar sx={{ display: { xs: "block", md: "none" } }} className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={onClose} aria-label="Close" size="large">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {dialogTitle && <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>}
        <div
          className={footer ? classes.body : null}
          style={{
            maxWidth: "100%",
            overflowX: "hidden",
          }}
        >
          {children}
        </div>
        {footer && <Paper className={classes.footer}>{footer}</Paper>}
      </Dialog>
    </div>
  );
};

ResponsiveDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string,
};

export default withStyles(styles)(ResponsiveDialog);
