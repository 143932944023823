import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = {
  button: {
    width: 300,
    height: 35,
    justifyContent: "flex-start",
  },
};

class CalendarButton extends Component {
  render() {
    const { classes, onClick, value } = this.props;
    return (
      <Button variant="outlined" color="inherit" className={classes.button} onClick={onClick}>
        {value}
      </Button>
    );
  }
}

CalendarButton.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
};

export default withStyles(styles)(CalendarButton);
