import React, { Component } from "react";
import performRequest from "../../../network/perform-request";
import { competitionsShowRoute } from "../../../network/api-routes";
import { Button, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { CloudOutlined, ErrorOutline, MonetizationOnOutlined, PeopleOutline } from "@mui/icons-material";
import CompetitionSignUps from "./CompetitionSignUps";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  title: {
    [theme.breakpoints.down("xl")]: {
      fontSize: 50,
    },
    textAlign: "center",
    fontSize: 70,
    fontWeight: 600,
  },
  cardValue: {
    fontSize: "3rem",
    fontWeight: 600,
  },
  cardTitle: {
    fontWeight: 400,
  },
  card: {
    padding: theme.spacing(3),
  },
  iconContainer: {
    backgroundColor: theme.palette.background.default,
    color: "white",
    padding: 10,
    margin: "auto auto 24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: 84,
    height: 84,
    justifyContent: "center",
  },
  icon: {
    fontSize: 50,
  },
  titleContainer: {},
});

const IconCard = (props) => {
  const { title, value, icon, classes } = props;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container direction="column" spacing={0}>
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12}>
              <Grid className={classes.iconContainer}>{icon}</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography gutterBottom className={classes.cardValue} color="textPrimary">
                {value}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h3" className={classes.cardTitle}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

class Competition extends Component {
  constructor(props) {
    super();

    this.state = {
      id: props.match.params.id,
      ready: false,
      competitions: [],
      selectedIndex: 0,
      meta: null,
    };
  }

  componentDidMount() {
    this.fetch(this.props.userContext.user);
  }

  fetch(user) {
    performRequest("POST", competitionsShowRoute, { competition_uuid: this.state.id }, true, user)
      .then((response) => {
        this.setState({
          competition: response.data.data,
          meta: response.data.meta,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  formatAmount(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }

  renderSignUpsTable() {
    const { competition } = this.state;

    return (
      <CompetitionSignUps
        competition={competition}
        onUpdate={() => {
          this.fetch();
        }}
      ></CompetitionSignUps>
    );
  }

  iconCardContainer(children) {
    return (
      <Grid item xs={12} md={6} lg={3}>
        {children}
      </Grid>
    );
  }

  renderCards() {
    const { classes } = this.props;
    const { meta } = this.state;

    return (
      <Grid container style={{ marginTop: 60 }} spacing={4}>
        {this.iconCardContainer(
          <IconCard
            classes={classes}
            title="Participantes"
            value={meta.participants_count}
            icon={<PeopleOutline className={classes.icon}></PeopleOutline>}
          />
        )}
        {this.iconCardContainer(
          <IconCard
            classes={classes}
            title="Inscripciones online"
            value={meta.online_sign_ups}
            icon={<CloudOutlined className={classes.icon}></CloudOutlined>}
          />
        )}
        {this.iconCardContainer(
          <IconCard
            classes={classes}
            title="Pagos pendientes"
            value={meta.pending_sign_ups}
            icon={<ErrorOutline className={classes.icon}></ErrorOutline>}
          />
        )}
        {this.iconCardContainer(
          <IconCard
            classes={classes}
            title="Ingresos"
            value={this.formatAmount(meta.sign_ups_amount)}
            icon={<MonetizationOnOutlined className={classes.icon}></MonetizationOnOutlined>}
          />
        )}
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    const { competition } = this.state;
    if (!this.state.ready) {
      return (
        <Grid item container justifyContent="center" style={{ marginTop: 30 }}>
          <CircularProgress size={24}></CircularProgress>
        </Grid>
      );
    }

    const noTournaments = competition.tournaments_count === 0;
    return (
      <>
        {noTournaments && (
          <Grid container item xs={12} style={{ height: "100vh" }}>
            <Grid item container xs={4} justifyContent="center" alignItems="center">
              <Grid container spacing={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} container justifyContent="center">
                    <Typography variant="h1" style={{ fontSize: 35 }}>
                      ¡Bienvenido a tu evento!
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Typography style={{ fontSize: 21 }}>Puedes empezar creando un torneo</Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} container justifyContent="center">
                  <Link to={`/evento/${this.state.id}/crear-torneo`}>
                    <Button className={`cta ${classes.createButton}`} size="large">
                      Crear un torneo
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={8} justifyContent="flex-start" alignItems="center">
              <img alt="placeholder" style={{ height: "40vh" }} src="/img/welcome.svg" />
            </Grid>
          </Grid>
        )}

        {!noTournaments && (
          <>
            <Grid container>
              <Grid
                item
                xs={12}
                className="background background-filter"
                justifyContent="center"
                style={{
                  backgroundImage: `url('${this.state.competition.full_cover_url}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  alignItems: "center",
                  backgroundPosition: "center",
                }}
              >
                <span>
                  <Typography variant="h1" className={classes.title}>
                    {this.state.competition.name}
                  </Typography>
                </span>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                {this.renderCards()}
                <Grid item xs={12} style={{ marginTop: 60 }}>
                  {this.renderSignUpsTable()}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
}

export default withStyles(styles)(Competition);
