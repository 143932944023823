import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
// import { KeyboardArrowDown } from '@mui/icons-material';
import logo from "../../assets/iso-white.svg";
// import whiteLogo from "../../assets/iso-white.svg";

const MenuButton = (props) => {
  const { classes } = props;
  let src = logo;
  // if (props.isTransparent) {
  //   src = whiteLogo;
  // }
  return (
    <Grid item className={classes.menuButton} sx={{ flexGrow: 1 }}>
      <Link sx={{ display: { xs: "none", md: "flex" } }} to="/">
        <img src={src} className={classes.logo} alt="logo" />
      </Link>
      {/* <Hidden only={["md", "lg", "xl"]}>
        <Button onClick={props.toggleDrawer(true)}>
          <img src={src} className={classes.logo} alt="logo" />
          <KeyboardArrowDown color="inherit" />
        </Button>
      </Hidden> */}
    </Grid>
  );
};

export default MenuButton;
