import React, { Component } from "react";
import performRequest from "../../../network/perform-request";
import {
  competitionsUsersRoute,
  competitionsUsersInviteRoute,
  competitionUsersCancelInviteRoute,
  competitionUsersDeleteRoute,
} from "../../../network/api-routes";
import Divider from "@mui/material/Divider";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Avatar,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import "moment/locale/es";
import AlertDialog from "../../common/AlertDialog";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  container: {
    padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
    maxWidth: 1000,
  },
  contentContainer: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    marginTop: 30,
  },
  tournamentContainer: {
    padding: `${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(6)}`,
  },
  tournamentBanner: {
    display: "block",
    width: 105,
    height: 140,
    objectFit: "cover",
    position: "relative",
    backgroundColor: "gray",
  },
  listItemText: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: 400,
    fontSize: 48,
    color: theme.palette.text.primary,

    // color:
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  icon: {
    color: theme.palette.divider,
  },
  iconLabel: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginBottom: 2,
  },

  createButton: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    fontSize: 20,
    fontWeight: 600,
    textTransform: "none",
  },
  tournamentInfo: {
    color: theme.palette.text.primary,
  },
  progressBarContainer: {
    marginTop: 10,
    borderRadius: 5,
    height: 10,
    width: 200,
    border: `1px solid #E4E5ED`,
    backgroundColor: "white",
  },
  tournamentRow: {
    color: theme.palette.text.primary,
  },
  progressBar: {
    backgroundColor: theme.palette.main,
  },
});

class CompetitionAdmins extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      ready: false,
      users: [],
      selectedIndex: 0,
      openModal: false,
      email: "",
      userInvites: [],
      cancelInviteOpen: false,
      selectedUserInvite: null,
      deleteUserOpen: false,
      selectedUser: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.submitInvite = this.submitInvite.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cancelInvite = this.cancelInvite.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    this.fetch(this.props.userContext.user);
  }

  fetch(user) {
    performRequest("POST", competitionsUsersRoute, { competition_uuid: this.state.id }, true, user)
      .then((response) => {
        this.setState({
          users: response.data.data,
          userInvites: response.data.meta.pending_invites,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  reset() {
    this.setState({
      email: "",
      selectedUserInvite: null,
      selectedUser: null,
    });
  }

  submitInvite() {
    const { user } = this.props.userContext;
    this.handleClose();
    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        competitionsUsersInviteRoute,
        {
          competition_uuid: this.state.id,
          email: this.state.email,
        },
        true,
        user
      )
        .then((response) => {
          this.reset();

          this.fetch();
        })
        .catch((error) => {
          this.reset();
          this.fetch();
        });
    });
  }

  cancelInvite() {
    const { user } = this.props.userContext;
    this.handleClose();
    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        competitionUsersCancelInviteRoute,
        {
          user_invite_id: this.state.selectedUserInvite.id,
        },
        true,
        user
      )
        .then((response) => {
          this.setState(
            {
              selectedUserInvite: null,
              ready: false,
            },
            () => {
              this.fetch();
            }
          );
        })
        .catch((error) => {
          this.fetch();
        });
    });
  }

  handleClose() {
    this.setState({ openModal: false });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderAddDialog() {
    return (
      <div>
        <Dialog open={this.state.openModal} onClose={this.handleClose}>
          <DialogTitle>Invitar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Invita a los administradores del torneo a que te ayuden a manejarlo ingresando su email
            </DialogContentText>
            <TextField
              autoFocus
              size="small"
              id="name"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              value={this.state.email}
              onChange={this.handleChange("email")}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Volver</Button>
            <Button onClick={this.submitInvite}>Enviar invitación</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  renderConfirmCancelInviteDialog() {
    return (
      <AlertDialog
        hideButton
        isOpen={this.state.cancelInviteOpen}
        onConfirm={() => this.setState({ cancelInviteOpen: false }, this.cancelInvite)}
        onCancel={() => this.setState({ cancelInviteOpen: false })}
        alertBody="¿Estás seguro que quieres cancelar la invitación?"
        confirmButtonTitle="Si, estoy seguro"
        cancelButtonTitle="Volver atrás"
      />
    );
  }

  deleteUser() {
    const { user } = this.props.userContext;
    this.handleClose();
    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        competitionUsersDeleteRoute,
        {
          competition_uuid: this.state.id,
          user_id: this.state.selectedUser.id,
        },
        true,
        user
      )
        .then((response) => {
          this.setState(
            {
              selectedUserInvite: null,
              ready: false,
            },
            () => {
              this.fetch();
            }
          );
        })
        .catch((error) => {
          this.fetch();
        });
    });
  }
  renderConfirmDeleteUserDialog() {
    return (
      <AlertDialog
        hideButton
        isOpen={this.state.deleteUserOpen}
        onConfirm={() => this.setState({ deleteUserOpen: false }, this.deleteUser)}
        onCancel={() => this.setState({ deleteUserOpen: false })}
        alertBody="¿Estás seguro que quieres remover a este usuario?"
        confirmButtonTitle="Si, estoy seguro"
        cancelButtonTitle="Volver atrás"
      />
    );
  }

  render() {
    const { classes } = this.props;

    if (!this.state.ready) {
      return (
        <Grid item container justifyContent="center" style={{ marginTop: 30 }}>
          <CircularProgress size={24}></CircularProgress>
        </Grid>
      );
    }

    return (
      <>
        <Grid container className={classes.root}>
          <Grid className={classes.container} container item xs={12} justifyContent="space-between" spacing={8}>
            <Grid item container justifyContent="space-between" xs={12}>
              <Typography variant="h1" className={classes.title}>
                Administradores
              </Typography>
              <Button
                onClick={() => {
                  this.setState({ openModal: true });
                }}
                className={`cta ${classes.createButton}`}
              >
                Agregar
              </Button>
            </Grid>
            {this.state.users.map((user) => (
              <Grid container item xs={12} key={user.id} justifyContent="space-between">
                <Grid item xs={8} container alignItems="center">
                  <Grid item>
                    <Avatar
                      src={user.full_profile_pic_url}
                      style={{ height: 40, width: 40, marginRight: 40 }}
                      alt={user.full_name}
                    ></Avatar>
                  </Grid>
                  <Grid item>
                    <Typography variant="h3">{user.full_name}</Typography>
                    <Typography>{user.email || "-"}</Typography>
                  </Grid>
                </Grid>

                {user.editable && (
                  <Grid item xs={4} container justifyContent="flex-end" spacing={2}>
                    {/* <Grid item>
                    <Button variant="outlined">Editar</Button>
                  </Grid> */}
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          this.setState({ selectedUser: user, deleteUserOpen: true });
                        }}
                      >
                        Remover acceso
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ))}

            {this.state.userInvites.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
                <Grid container item spacing={2}>
                  <Typography variant="h2" style={{ marginBottom: 40 }}>
                    Invitaciones pendientes
                  </Typography>
                  <Grid container spacing={1}>
                    {this.state.userInvites.map((userInvite) => (
                      <Grid container item xs={12} key={userInvite.id} justifyContent="space-between" spacing={2}>
                        <Grid item xs={8} container alignItems="center">
                          <Grid item>
                            <Typography variant="h4">{userInvite.email}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} container justifyContent="flex-end" spacing={2}>
                          <Grid item>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                this.setState({ selectedUserInvite: userInvite, cancelInviteOpen: true });
                              }}
                            >
                              Cancelar invitación
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {this.renderAddDialog()}
        {this.renderConfirmCancelInviteDialog()}
        {this.renderConfirmDeleteUserDialog()}
      </>
    );
  }
}

export default withStyles(styles)(CompetitionAdmins);
