import React from "react";
import PropTypes from "prop-types";
import { signInRoute, facebookLoginRoute, baseUrl, baseDomain } from "../../../network/api-routes";
import UserContext from "../../../context/UserContext";
import { Typography, Grid, Divider, Button, FormControl, Input, InputLabel } from "@mui/material";
import axios from "axios";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  errorMessage: {
    maxWidth: "100%",
    color: theme.palette.text.secondary,
  },
});

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: false,
      errorMessage: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
  }

  handleSubmit(event, changeUser) {
    event.preventDefault();
    axios
      .request({
        method: "GET",
        url: `${baseDomain}/sanctum/csrf-cookie`,
        withCredentials: true,
      })
      .then((response) => {
        axios
          .post(
            `${baseDomain}${signInRoute}`,
            {
              email: this.state.email,
              password: this.state.password,
            },
            { withCredentials: true }
          )
          .then((response) => {
            const user = response.data.data;
            changeUser(user);
            this.props.successCallback();
          })
          .catch((error) => {
            console.log(error);
            let errorMessage = error.response.data.message;

            if (error.response.data.status_code === 401) {
              errorMessage = "Parece que tus datos no son correctos";
            }
            this.setState({
              error: true,
              errorMessage: errorMessage,
            });
          });
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  responseFacebook(response, changeUser) {
    console.log(response);
    //Log in user with token
    axios
      .post(`${baseUrl}${facebookLoginRoute}`, {
        fbtoken: response.accessToken,
      })

      .then((response) => {
        const user = response.data.data;
        const token = response.data.meta.token;
        changeUser(user, token);
        this.props.successCallback();
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMessage: "Ha ocurrido un error al intentar ingresar con Facebook",
        });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <UserContext.Consumer>
        {(userContext) => (
          <Grid container className={classes.container}>
            {/* <FacebookButton
              responseFacebook={response => {
                this.responseFacebook(response, userContext.changeUser);
              }}
            />
            <br /> */}
            {/* <Typography variant="caption">o también</Typography> */}
            <form
              onSubmit={(event) => {
                this.handleSubmit(event, userContext.changeUser);
              }}
              className={classes.form}
            >
              <FormControl variant="standard" margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </FormControl>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Contraseña</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                />
              </FormControl>
              {this.state.error && (
                <div className={classes.errorMessage}>
                  <Typography color="inherit">{this.state.errorMessage}</Typography>
                </div>
              )}
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Divider light />
              </Grid>
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Ingresar
              </Button>
            </form>
          </Grid>
        )}
      </UserContext.Consumer>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
