import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import RevaThemeProvider from "./providers/ThemeProvider";
import SnackbarWrapper from "./providers/SnackbarWrapper";
import axios from "axios";
import { CssBaseline } from "@mui/material";
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
ReactDOM.render(
  <RevaThemeProvider>
    <CssBaseline />
    <SnackbarWrapper>
      <App />
    </SnackbarWrapper>
  </RevaThemeProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
