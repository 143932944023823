import React, { Component } from "react";
import { Button, Grid, CircularProgress, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import { participantsMoveToTournament, tournamentsIndexRoute } from "../../../network/api-routes";
import performRequest from "../../../network/perform-request";
import withUserContext from "../../hoc/withUserContext";
import { withSnackbar } from "notistack";

const styles = (theme) => ({
  modalContainer: {
    width: "100%",
    padding: 30,
    margin: "auto",
    borderRadius: 5,
    minHeight: 500,
  },
});

class ChangeTournamentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tournaments: [],
      ready: false,
      selectedTournamentId: "",
      selectedCategoryId: "",
    };
  }

  componentDidMount() {
    this.getTournaments(this.props.userContext.user, this.props.competitionId);
  }

  getTournaments(user, competitionId) {
    performRequest(
      "POST",
      tournamentsIndexRoute,
      {
        state: "pending",
        competition_id: competitionId,
      },
      true,
      user
    )
      .then((response) => {
        this.setState({
          tournaments: response.data.data,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onSave() {
    this.setState({
      ready: false,
    });
    const { user } = this.props.userContext;
    performRequest(
      "POST",
      participantsMoveToTournament,
      {
        participant_id: this.props.participant.id,
        tournament_id: this.state.selectedTournamentId,
        category_id: this.state.selectedCategoryId,
      },
      true,
      user
    )
      .then((response) => {
        this.props.enqueueSnackbar(response.data.message, {
          variant: "success",
        });
        this.props.onSave();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          ready: true,
        });
        this.props.enqueueSnackbar(error.response.data.message, {
          variant: "error",
        });
        console.log(error);
      });
  }

  handleTournamentChange = (event) => {
    this.setState({
      selectedTournamentId: event.target.value,
      selectedCategoryId: "",
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    if (!this.state.ready) {
      return (
        <Grid container justifyContent="center" alignItems="center" className={classes.modalContainer}>
          <CircularProgress />
        </Grid>
      );
    }

    let selectedTournament = null;
    if (this.state.tournaments && this.state.selectedTournamentId) {
      const id = this.state.selectedTournamentId;
      selectedTournament = this.state.tournaments.find((t) => {
        return t.id === id;
      });
    }

    return (
      <Grid container justifyContent="center" className={classes.modalContainer}>
        <Typography variant="h1">Cambiar de torneo</Typography>
        <Grid container>
          {this.state.tournaments && (
            <Grid item xs={12}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="tournament-select">Torneo</InputLabel>
                <Select
                  variant="standard"
                  id="tournament-select"
                  label="Torneo"
                  value={this.state.selectedTournamentId}
                  onChange={this.handleChange("selectedTournamentId")}>
                  {this.state.tournaments.map((tournament) => {
                    return (
                      <MenuItem key={tournament.id} value={tournament.id}>
                        {tournament.competition.name} - {tournament.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Button
            disabled={!selectedTournament}
            variant="contained"
            size="large"
            color="primary"
            onClick={this.onSave.bind(this)}
          >
            Cambiar de torneo
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withSnackbar(withUserContext(withStyles(styles)(ChangeTournamentModal)));
