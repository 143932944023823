import React, { Component } from "react";
import { Grid, Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Redirect } from "react-router-dom";
import EditParticipantModal from "./Tournament/EditParticipantModal";

const styles = (theme) => ({
  layout: {
    width: "auto",
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // marginTop: theme.spacing(`3`),
    // [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
    //     width: 1100,
    //     marginLeft: "auto",
    //     marginRight: "auto",
    // },
    justify: "center",
    color: theme.palette.text.secondary,
  },
});

class Playground extends Component {
  render() {
    const { classes } = this.props;

    if (process.env.REACT_APP_ENV !== "local") {
      return (
        <Redirect
          to={{
            pathname: `/`,
          }}
        />
      );
    }

    return (
      <Grid container className={classes.layout}>
        <Grid item container xs={12} justifyContent="center">
          {/* <EditMatchModal
                        matchId={389530}
                        tabName={"scoreDetails"}
                        canEdit={true}
                        showScoreTab={true}
                    /> */}
          <Paper item style={{ maxWidth: 600 }}>
            <EditParticipantModal participantId={5073} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Playground);
