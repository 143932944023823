import React, { useEffect, useState } from "react";
import performRequest from "network/perform-request";
import { getRankingImage } from "network/api-routes";

const LeagueRanking = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const { match } = props;

  useEffect(() => {
    // Fetch the image URL from the backend=
    performRequest(
      "POST",
      getRankingImage,
      { classification_uuid: match.params.uuid },
      null,
      null,
      null,
      {},
      false,
      "blob"
    )
      .then((response) => {
        console.log(response);
        const imageUrl = URL.createObjectURL(response.data);
        setImageUrl(imageUrl);
      })
      .catch((error) => {
        console.error("Error fetching the ranking image:", error);
        setErrorMessage(error.message);
      });
  }, [match.params.uuid]);

  return (
    <div>
      {imageUrl ? <img src={imageUrl} alt="League Ranking" width={500} /> : <p>{errorMessage || "Loading..."}</p>}
    </div>
  );
};

export default LeagueRanking;
