import axios from "../lib/axios";
import { baseUrl } from "./api-routes";

export const performRequest = (
  method,
  url,
  params,
  auth,
  user,
  page,
  headers = {},
  fullUrl = false,
  responseType = "json" // Default response type is 'json'
) => {
  const body = method === "get" ? "params" : "data";
  const urlParam = page ? `?page=${page}` : "";
  const config = {
    method,
    url: fullUrl ? urlParam : `${url}${urlParam}`,
    timeout: 0,
    baseURL: baseUrl,
    [body]: params || {},
    withCredentials: true,
    credentials: true,
    headers: Object.assign(headers, {
      Accept: "application/json",
    }),
    responseType, // Set the response type
  };

  const response = axios.request(config);
  return response;
};

export default performRequest;
