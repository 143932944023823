import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Tabs,
  Tab,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  InputAdornment,
  Switch,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import TimeIcon from "@mui/icons-material/AccessTime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classNames from "classnames";
import moment from "moment";
import { subDays } from "date-fns";

import es from "date-fns/locale/es";
import RevaLogo from "../../../assets/iso-white.svg";
import { matchesUpdateRoute, matchesShowRoute } from "../../../network/api-routes";
import performRequest from "../../../network/perform-request";
import withUserContext from "../../hoc/withUserContext";
import CalendarButton from "../../common/CalendarButton";
import PlaceIcon from "@mui/icons-material/Place";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import _ from "lodash";
import MatchScoreDetails from "../../common/MatchScoreDetails";
const CLOSED = "closed";

const styles = (theme) => ({
  modalContainer: {
    width: "100%",
    padding: 30,
    margin: "auto",
    borderRadius: 5,
    minHeight: 600,
    minWidth: 900,
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  liveNow: {
    margin: "20px 0 10px auto",
  },
  addSetButton: {
    float: "right",
    margin: "20px 0 10px auto",
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  scoreTableContainer: {
    width: "100%",
    overflow: "auto",
  },
  score: {
    width: 70,
  },
  scoreInput: {
    fontSize: 16,
  },
  scoreButton: {
    margin: "auto",
    fontSize: 18,
    fontWeight: 700,
    padding: "15px 30px",
    color: "#FFFFFF",
  },
  winnerContainer: {
    textAlign: "center",
    fontWeight: 700,
    margin: "30px 0",
  },
  pickWinnerButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  selectedWinnerButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
  },
  submitScoreContainer: {
    marginTop: 30,
    padding: 30,
    textAlign: "center",
    backgroundColor: theme.palette.action.selected,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  submitDateContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
  },
  playerCard: {
    flex: 1,
    textAlign: "center",
    fontWeight: 600,
    backgroundColor: theme.palette.action.selected,
  },
  picContainer: {
    width: 70,
    height: 70,
    margin: "20px auto",
    display: "flex",
    borderRadius: 50,
  },
  avatar: {
    flex: 1,
    padding: 15,
    width: "inherit",
    objectFit: "contain",
  },
  profilePic: {
    width: "inherit",
    borderRadius: 50,
  },
  detailsTable: {
    width: "auto",
    margin: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.divider,
  },
  scoreDetails: {
    padding: "15px 24px",
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderColor: "inherit",
  },
});

const MATCH_IN_PROGRESS = 0;
const TIE = -1;

const TABS = {
  details: 0,
  score: 1,
  scoreDetails: 2,
};

class EditMatchModal extends Component {
  constructor(props) {
    super(props);

    let tabIndex = 0;
    if (this.props.tabName) {
      tabIndex = TABS[this.props.tabName];
    }

    this.state = {
      tabIndex,
      ready: false,
      showScoreDetailsTab: false,
      hasLive: false,
      channel_name: "",
      liveNow: false,
    };
    this.handleSpaceChange = this.handleSpaceChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.debouncedUpdateScore = _.debounce(this.updateScore, 250, {
      maxWait: 1000,
    });
    this.debouncedUpdateScore = this.debouncedUpdateScore.bind(this);
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    const { user } = this.props;
    performRequest(
      "POST",
      matchesShowRoute,
      {
        match_id: this.props.matchId,
      },
      true,
      user
    )
      .then((result) => {
        const matchInfo = result.data.data;
        const scoresBySet = matchInfo.scores_csv && matchInfo.scores_csv.split(",");
        const tournament = matchInfo.tournament.data;
        let playerScores = [];

        if (scoresBySet) {
          scoresBySet.forEach((set) => {
            const score = set.split("-").map(Number);
            playerScores.push([score[0], score[1]]);
          });
        }

        const startDate = matchInfo.scheduled_time;
        let defaultScores = [[0, 0]];
        if (tournament.default_sets > 1) {
          for (let i = 0; i < tournament.default_sets - 1; i++) {
            defaultScores.push([0, 0]);
          }
        }

        let winner = matchInfo.winner_id;
        if (matchInfo.in_progress) {
          winner = MATCH_IN_PROGRESS;
        }

        this.setState({
          matchInfo: matchInfo,
          playerScores: playerScores.length ? playerScores : defaultScores,
          scoresBySet: scoresBySet,
          winner,
          startDate: startDate ? moment(startDate).toDate() : null,
          spaces: result.data.meta.spaces.data,
          showScoreDetailsTab: result.data.meta.score_details_allowed,
          selectedSpaceId: matchInfo.space_id || "",
          walkover: matchInfo.is_walkover,
          duration: matchInfo.match_duration || 60,
          ready: true,
          tournament,
          hasLive: matchInfo.has_live,
          channel_name: matchInfo.channel_name,
          liveNow: matchInfo.live_now,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateScore(callback) {
    const scoresCSV = [...this.state.playerScores]; // We clone it since we need to modify the array
    const { user } = this.props;
    const { matchInfo } = this.state;

    if (scoresCSV && scoresCSV.length) {
      for (let i = 0; i < scoresCSV.length; i++) {
        if (typeof scoresCSV[i].join === "function") {
          scoresCSV.splice(i, 1, scoresCSV[i].join("-"));
        }
      }
    }
    performRequest(
      "POST",
      matchesUpdateRoute,
      {
        match_id: matchInfo.id,
        scores_csv: scoresCSV ? scoresCSV.join(",") : null,
        live_now: this.state.liveNow,
      },
      true,
      user
    )
      .then((result) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  updateMatch(callback) {
    const { user } = this.props;
    const { winner, walkover, matchInfo } = this.state;
    const scoresCSV = this.state.playerScores;

    console.log(scoresCSV);
    if (scoresCSV && scoresCSV.length) {
      for (let i = 0; i < scoresCSV.length; i++) {
        if (typeof scoresCSV[i].join === "function") {
          scoresCSV.splice(i, 1, scoresCSV[i].join("-"));
        }
      }
    }
    const data = {
      match_id: matchInfo.id,
      winner_id: winner === MATCH_IN_PROGRESS || winner === TIE ? null : winner,
      scores_csv: scoresCSV ? scoresCSV.join(",") : null,
      is_tie: winner === TIE,
      in_progress: winner === MATCH_IN_PROGRESS,
      is_walkover: walkover,
    };
    console.log("data to post", data);
    performRequest("POST", matchesUpdateRoute, data, true, user)
      .then((result) => {
        if (callback) {
          callback(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  update(callback) {
    const { user } = this.props;
    const { matchInfo } = this.state;
    const scheduledTime = moment(this.state.startDate);
    const data = {
      match_id: matchInfo.id,
      scheduled_time: scheduledTime.format("YYYY-MM-DD HH:mm:ss"),
      space_id: this.state.selectedSpaceId,
      duration: this.state.duration && typeof this.state.duration === "number" ? this.state.duration : 60,
      has_live: this.state.hasLive,
      channel_name: this.state.hasLive ? this.state.channel_name : null,
    };
    console.log("data to post", data);
    performRequest("POST", matchesUpdateRoute, data, true, user)
      .then((result) => {
        if (callback) {
          callback(result.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange = (setIndex, playerIndex) => (event) => {
    const { matchInfo } = this.state;
    const participants = [matchInfo.player1.data, matchInfo.player2.data];
    const newPlayerScores = this.state.playerScores;
    let winnerId = this.state.winner;
    let playerOneSets = 0;
    let playerTwoSets = 0;

    console.log(newPlayerScores, newPlayerScores[setIndex][playerIndex]);
    if (setIndex !== null && playerIndex !== null) {
      newPlayerScores[setIndex][playerIndex] = Number(event.target.value);
    }

    newPlayerScores.forEach((set) => {
      if (set[0] > set[1]) {
        playerOneSets++;
      }
      if (set[0] < set[1]) {
        playerTwoSets++;
      }
    });

    if (playerOneSets > playerTwoSets) {
      winnerId = participants[0].id;
    }
    if (playerOneSets < playerTwoSets) {
      winnerId = participants[1].id;
    }
    if (playerOneSets === playerTwoSets) {
      winnerId = -1;
    }

    this.setState(
      {
        playerScores: newPlayerScores,
        winner: winnerId,
      },
      () => {
        this.debouncedUpdateScore();
      }
    );
  };

  handleSelectChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  }

  handleInputChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  }

  handleSwitchChange(event) {
    const targetName = event.target.name;

    this.setState(
      {
        ...this.state,
        [event.target.name]: !this.state[event.target.name],
      },
      () => {
        if (targetName === "liveNow") {
          this.updateScore();
        }
        console.log("state after switch update", this.state);
      }
    );
  }

  onSetChange(action) {
    const scores = this.state.playerScores;

    if (action === "add") {
      scores.push([0, 0]);
      this.handleChange();
    } else {
      scores.pop();
    }

    this.setState({
      playerScores: scores,
    });
  }

  handleDateChange(date) {
    this.setState({
      startDate: date,
    });
  }

  handleSpaceChange(event) {
    this.setState({
      selectedSpaceId: event.target.value,
    });
  }

  handleTextInputChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderTeamScoreDetails(participant) {
    const { matchInfo } = this.state;
    const team = participant.team.data;
    const teamPlayers = team.players.data;
    const standings = matchInfo.score_details["standingsByPlayerId"];
    const goalers = teamPlayers.filter((teamPlayer) => standings[teamPlayer.id].goals > 0);
    const warnedPlayers = teamPlayers.filter((teamPlayer) => standings[teamPlayer.id].warnings > 0);
    const expelledPlayers = teamPlayers.filter((teamPlayer) => standings[teamPlayer.id].bans > 0);

    return (
      <Grid item xs={4} style={{ textAlign: "center" }}>
        {goalers.length > 0 && (
          <Grid>
            <Typography>
              <span role="img" aria-label="goals">
                ⚽
              </span>
              {" Goleadores "}
              <span role="img" aria-label="goals">
                ⚽
              </span>
            </Typography>
            <Typography>
              {goalers.map((player) => `${player.name}(${standings[player.id].goals})`).join(", ")}
            </Typography>
          </Grid>
        )}
        {warnedPlayers.length > 0 && (
          <Grid justifyContent="center">
            <Typography>🟨 Amonestados 🟨</Typography>
            <Typography>{warnedPlayers.map((player) => player.name).join(", ")}</Typography>
          </Grid>
        )}
        {expelledPlayers.length > 0 && (
          <Grid justifyContent="center">
            <Typography>🟥 Expulsados 🟥</Typography>
            <Typography>{expelledPlayers.map((player) => player.name).join(", ")}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  renderDetails() {
    const { classes, onSubmitScore, canEdit } = this.props;
    const { scoresBySet, matchInfo, tournament } = this.state;

    if (!this.state.ready) {
      return (
        <Grid container justifyContent="center" alignItems="center" className={classes.modalContainer}>
          <CircularProgress />
        </Grid>
      );
    }

    const participants = [matchInfo.player1, matchInfo.player2];
    const participant1Pic = participants[0] && participants[0].data.full_profile_pic_url;
    const participant2Pic = participants[1] && participants[1].data.full_profile_pic_url;

    const tournamentStarted = tournament.state !== "pending";

    return (
      <div>
        <Grid container style={{ marginTop: 30 }}>
          <Card className={classes.playerCard}>
            <CardContent>
              <div className={classes.picContainer}>
                <img
                  alt="Jugador 1"
                  src={participant1Pic || RevaLogo}
                  className={participant1Pic ? classes.profilePic : classes.avatar}
                />
              </div>
              {(participants[0] && participants[0].data.name) || "-"}
            </CardContent>
          </Card>
          <div style={{ margin: "auto 30px" }}>vs</div>
          <Card className={classes.playerCard}>
            <CardContent>
              <div className={classes.picContainer}>
                <img
                  alt="Jugador 2"
                  src={participant2Pic || RevaLogo}
                  className={participant2Pic ? classes.profilePic : classes.avatar}
                />
              </div>
              {(participants[1] && participants[1].data.name) || "-"}
            </CardContent>
          </Card>
          <Card />
        </Grid>
        {scoresBySet && (
          <Grid container style={{ marginTop: 20 }}>
            {this.renderTeamScoreDetails(participants[0].data)}
            <Grid item xs={4}>
              <Table className={classes.detailsTable}>
                <TableBody>
                  {participants.map((player, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row" style={{ minWidth: 200 }}>
                        {(player && player.data.name) || "-"}
                      </TableCell>
                      {scoresBySet.map((set, setId) => (
                        <TableCell key={`Set ${setId + 1}`} component="th" scope="row" className={classes.scoreDetails}>
                          {set.split("-")[i]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            {this.renderTeamScoreDetails(participants[1].data)}
          </Grid>
        )}
        {canEdit && (
          <div className={classNames(classes.submitScoreContainer, classes.submitDateContainer)}>
            {tournamentStarted ? (
              <Grid container spacing={5} justifyContent="flex-end" alignItems="center">
                <Grid item xs={12} container alignItems="center">
                  <Grid item container justifyContent="center" alignItems="center" xs={2}>
                    <CalendarTodayIcon style={{ marginRight: 5, fontSize: 16 }} />
                    <Typography style={{ marginRight: 20, fontWeight: 600 }}>Fecha y hora</Typography>
                  </Grid>
                  <Grid item container alignItems="center" justifyContent="flex-start" xs={4}>
                    <DatePicker
                      customInput={<CalendarButton />}
                      clearButtonTitle="Borrar"
                      popperPlacement="top-end"
                      selected={this.state.startDate}
                      onChange={this.handleDateChange}
                      minDate={subDays(new Date(), 0)}
                      locale={es}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeCaption="Hora"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                  <Grid item container justifyContent="center" alignItems="center" xs={2}>
                    <TimeIcon style={{ marginRight: 5, fontSize: 16 }} />
                    <Typography style={{ marginRight: 20, fontWeight: 600 }}>Duración</Typography>
                  </Grid>
                  <Grid item container alignItems="center" justifyContent="flex-start" xs={4}>
                    <FormControl variant="standard" className={classes.formControl}>
                      <TextField
                        variant="standard"
                        value={this.state.duration}
                        label=""
                        type={"number"}
                        onChange={this.handleTextInputChange("duration")}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">minutos</InputAdornment>,
                        }}
                      />
                    </FormControl>
                    {/* <Select
                      value={this.state.duration}
                      name="duration"
                      onChange={this.handleSelectChange}
                    >
                      {[
                        15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180,
                        210, 240,
                      ].map((minutes) => (
                        <MenuItem key={minutes} value={minutes}>
                          {minutes} minutos
                        </MenuItem>
                      ))}
                    </Select> */}
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                  <Grid item container justifyContent="center" alignItems="center" xs={2}>
                    <PlaceIcon style={{ fontSize: 16, marginRight: 5 }} />
                    <Typography style={{ fontWeight: 600 }}>Lugar</Typography>
                  </Grid>
                  <Grid item container alignItems="center" justifyContent="flex-start" xs={4}>
                    {this.state.spaces && (
                      <React.Fragment>
                        {this.state.spaces.length === 0 && (
                          <small>Asigna locales a tu torneo para poder utilizar sus espacios</small>
                        )}
                        {this.state.spaces.length > 0 && (
                          <FormControl variant="outlined" style={{ width: 300, height: 35 }}>
                            <InputLabel id="space-select">Lugar</InputLabel>
                            <Select
                              variant="standard"
                              id="space-select"
                              label="Lugar"
                              defaultValue={this.state.selectedSpaceId}
                              onChange={this.handleSpaceChange}
                            >
                              {this.state.spaces.map((space) => {
                                return (
                                  <MenuItem key={space.id} value={space.id}>
                                    {space.display_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        )}
                      </React.Fragment>
                    )}
                    {!this.state.spaces && <CircularProgress size={14}></CircularProgress>}
                  </Grid>
                </Grid>

                <Grid item container xs={12} alignItems="center">
                  <Grid item container justifyContent="center" alignItems="center" xs={2}>
                    <Typography style={{ marginRight: 20, fontWeight: 600 }}>Tiene vivo</Typography>
                  </Grid>
                  <Grid item container alignItems="center" justifyContent="flex-start" xs={4}>
                    <Switch
                      checked={this.state.hasLive}
                      onChange={(event) => this.handleSwitchChange(event)}
                      color="primary"
                      name="hasLive"
                    />
                  </Grid>
                </Grid>

                {this.state.hasLive ? (
                  <Grid item container xs={12} alignItems="center">
                    <Grid item container justifyContent="center" alignItems="center" xs={2}>
                      <Typography style={{ marginRight: 20, fontWeight: 600 }}>Canal</Typography>
                    </Grid>
                    <Grid item container alignItems="center" justifyContent="flex-start" xs={4}>
                      <TextField
                        variant="standard"
                        value={this.state.channel_name}
                        label="Canal"
                        onChange={this.handleTextInputChange("channel_name")}
                      />
                    </Grid>
                  </Grid>
                ) : null}

                <Grid item container xs={12} justifyContent="center">
                  <Button className="cta" style={{ width: 100 }} onClick={() => this.update(onSubmitScore)}>
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <small>Podrás asignar fecha a este partido cuando inicie el torneo</small>
            )}
          </div>
        )}
      </div>
    );
  }

  renderScoring() {
    const { matchInfo } = this.state;

    const { isBracketStage, onSubmitScore, classes } = this.props;
    const { winner, playerScores } = this.state;
    const participants = [matchInfo.player1, matchInfo.player2];

    return (
      <div>
        <Grid item container xs={12} alignItems="center" className={classes.liveNow}>
          <Grid item container justifyContent="center" alignItems="center" xs={2}>
            <Typography style={{ marginRight: 20, fontWeight: 600 }}>En vivo ahora</Typography>
          </Grid>
          <Grid item container alignItems="center" justifyContent="flex-start" xs={4}>
            <Switch
              checked={this.state.liveNow}
              onChange={(event) => this.handleSwitchChange(event)}
              color="primary"
              name="liveNow"
            />
          </Grid>
        </Grid>
        <Button className={classes.addSetButton} onClick={() => this.onSetChange("add")}>
          Agregar set/partido
        </Button>
        {playerScores.length > 1 && (
          <Button className={classes.addSetButton} onClick={() => this.onSetChange("remove")}>
            Quitar set/partido
          </Button>
        )}
        <div className={classes.scoreTableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: 16, fontWeight: 600, flex: 1 }}>Jugador</TableCell>
                {playerScores.map((set, setId) => (
                  <TableCell key={setId} style={{ fontSize: 16, fontWeight: 600 }}>
                    {playerScores.length > 1 ? `${setId + 1}` : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {participants.map((player, i) => (
                // style={{
                //  backgroundColor: i ? "whitesmoke" : "white",
                //}}
                <TableRow key={player.data.id}>
                  <TableCell component="th" scope="row">
                    <Typography style={{ fontSize: 14 }}>{player.data.name}</Typography>
                  </TableCell>
                  {playerScores.map((set, setId) => (
                    <TableCell key={setId} component="th" scope="row">
                      <TextField
                        id="filled-bare"
                        key={`Set ${setId + 1}`}
                        className={classes.score}
                        InputProps={{
                          classes: {
                            root: classes.scoreInput,
                          },
                        }}
                        margin="normal"
                        type="number"
                        inputProps={{ min: 0 }}
                        variant="outlined"
                        defaultValue={set[i] || 0}
                        onChange={this.handleChange(setId, i)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="flex-end">
            <FormControlLabel
              control={<Checkbox checked={this.state.walkover} onChange={this.handleInputChange} name="walkover" />}
              label="Ganado por walkover"
            />
          </Grid>
        </div>
        <div className={classes.winnerContainer}>
          <Typography variant="h2">¿Quién ganó?</Typography>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{ margin: "0 5px" }}
              className={winner === participants[0].data.id ? classes.selectedWinnerButton : classes.pickWinnerButton}
              onClick={() => this.setState({ winner: participants[0].data.id })}
            >
              {participants[0].data.name}
            </Button>
            {!isBracketStage && (
              <Button
                variant="contained"
                style={{ margin: "0 5px" }}
                className={winner === -1 ? classes.selectedWinnerButton : classes.pickWinnerButton}
                onClick={() => this.setState({ winner: -1 })}
              >
                Empate
              </Button>
            )}
            {/* {!isClosed && (
              <Button
                variant="contained"
                style={{ margin: "0 5px" }}
                className={
                  winner === MATCH_IN_PROGRESS
                    ? classes.selectedWinnerButton
                    : classes.pickWinnerButton
                }
                onClick={() => this.setState({ winner: MATCH_IN_PROGRESS })}
              >
                Marcar como partido en curso
                  </Button>
            )} */}

            <Button
              variant="contained"
              style={{ margin: "0 5px" }}
              className={winner === participants[1].data.id ? classes.selectedWinnerButton : classes.pickWinnerButton}
              onClick={() => this.setState({ winner: participants[1].data.id })}
            >
              {participants[1].data.name}
            </Button>
          </div>
        </div>
        <div className={classes.submitScoreContainer}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!this.state.winner}
            className={classes.scoreButton}
            onClick={() => this.updateMatch(onSubmitScore)}
          >
            {matchInfo.state !== CLOSED && "Terminar partido"}
            {matchInfo.state === CLOSED && "Actualizar ganador"}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { matchInfo } = this.state;
    const { showScoreTab, classes } = this.props;
    const { tabIndex, showScoreDetailsTab } = this.state;

    if (!this.state.ready || !matchInfo) {
      return (
        <Grid container className={classes.modalContainer} justifyContent="center" alignContent="center">
          <CircularProgress size={24}></CircularProgress>
        </Grid>
      );
    }

    return (
      <div className={classes.modalContainer}>
        <Tabs
          value={tabIndex}
          classes={{ flexContainer: classes.tabs }}
          style={{ marginTop: 10 }}
          indicatorColor="primary"
          textColor="inherit"
          onChange={(event, value) => this.setState({ tabIndex: value })}
        >
          <Tab label="Información" />
          {showScoreTab && <Tab label="Resultados" />}
          {showScoreTab && showScoreDetailsTab && <Tab label="Detalles" />}
        </Tabs>
        {tabIndex === 0 && this.renderDetails()}

        {tabIndex === 1 && this.renderScoring()}

        {tabIndex === 2 && <MatchScoreDetails match={matchInfo} />}
      </div>
    );
  }
}

export default withUserContext(withStyles(styles)(EditMatchModal));
