import React from "react";
import PropTypes from "prop-types";
import { Img } from "react-image";
import { withStyles } from "@mui/styles";

const style = (theme) => ({
  placeholder: {
    backgroundColor: "#B6B8B8",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  placeholderImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

const Image = (props) => {
  const { classes } = props;
  const placeholder = (
    <Img
      className={classes.placeholderImage}
      src={`${props.src}?w=10&h=10&q=50&blur=2`}
      loader={<div className={classes.placeholder} />}
    />
  );

  return (
    <div className={props.className}>
      <Img
        src={`${props.src}`}
        loader={placeholder}
        className={props.className}
        unloader={<div className={classes.placeholder} />}
      />
    </div>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default withStyles(style)(Image);
