import React from "react";
import { getUserRoute, logoutRoute } from "./api-routes";
import performRequest from "./perform-request";
import { Redirect } from "react-router-dom";

export const getUser = () => {
  return performRequest("GET", getUserRoute, null, true);
};

const deleteCookie = function (name) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const logoutUser = (changeUser) => {
  performRequest("POST", logoutRoute, null, true)
    .then(() => {
      localStorage.setItem("isLoggedIn", false);
      changeUser(null, null);
      deleteCookie('XSRF-TOKEN');
      deleteCookie('cartalyst_sentinel');
      deleteCookie('laravel_session');

      return <Redirect to="/" />;
    })
    .catch((error) => {
      console.log("Logout failed", error);
    });

  return;
};
