import React, { Component } from "react";
import {
  Button,
  Grid,
  CircularProgress,
  Typography,
  TextField,
  Avatar,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import { searchUserRoute, assignUserRoute, playerUpdateRoute } from "../../../network/api-routes";
import performRequest from "../../../network/perform-request";
import withUserContext from "../../hoc/withUserContext";
import { withSnackbar } from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import AlertDialog from "../../common/AlertDialog";
const styles = (theme) => ({
  modalContainer: {
    width: "100%",
    padding: 30,
    margin: "auto",
    borderRadius: 5,
    minHeight: 500,
  },
  largeAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  searchUserContainer: {
    marginTop: theme.spacing(4),
  },
});

class EditPlayerModal extends Component {
  constructor(props) {
    super(props);

    const player = props.player;
    const isAppUser = Boolean(player.playerable_type === "App\\User" && player.playerable_id);

    let user = null;
    if (isAppUser) {
      user = player.playerable;
    }

    const name = player.name ? player.name : "";
    const phoneNumber = player.phone_number ? player.phone_number : "";
    const documentNumber = player.document_number ? player.document_number : "";

    this.state = {
      ready: true,
      name,
      phoneNumber,
      isAppUser,
      user,
      userPhoneNumber: "",
      userDocument: "",
      searchingUser: false,
      selectedUser: null,
      searchUserResult: null,
      searchEmpty: false,
      deleteAlertOpen: false,
      documentNumber,
      selectedTeamId: this.props.participant.team_id,
    };

    this.searchUser = this.searchUser.bind(this);
    this.assignUser = this.assignUser.bind(this);
    this.savePlayer = this.savePlayer.bind(this);
    this.removeAssignedUser = this.removeAssignedUser.bind(this);
  }

  savePlayer() {
    if (this.state.isAppUser) {
      return null;
    }

    this.setState({
      ready: false,
    });
    const { user } = this.props.userContext;
    performRequest(
      "POST",
      playerUpdateRoute,
      {
        player_id: this.props.player.id,
        name: this.state.name,
        phone_number: this.state.phoneNumber,
        document_number: this.state.documentNumber,
        team_id: this.state.selectedTeamId,
      },
      true,
      user
    )
      .then((response) => {
        this.props.enqueueSnackbar("Jugador actualizado", {
          variant: "success",
        });
        this.props.onSave();
      })
      .catch((error) => {
        this.setState({
          ready: true,
        });
        this.props.enqueueSnackbar(error.response.data.message, {
          variant: "error",
        });
        console.log(error);
      });
  }

  searchUser() {
    this.setState({
      searchingUser: true,
      searchUserResult: null,
      searchEmpty: false,
    });
    const { user } = this.props.userContext;
    performRequest(
      "POST",
      searchUserRoute,
      {
        phone_number: this.state.userPhoneNumber,
        document_number: this.state.userDocument,
      },
      true,
      user
    )
      .then((response) => {
        console.log("response is", response);
        this.setState({
          searchingUser: false,
          searchUserResult: response.data.data,
          searchEmpty: response.data.data ? false : true,
        });
      })
      .catch((error) => {
        this.setState({
          searchingUser: false,
          searchUserResult: null,
        });
        console.log("error is", error.response);
        if (error.response.status === 404) {
          this.props.enqueueSnackbar("No se encontró un usuario Reva con esos datos", {
            variant: "error",
          });
        } else {
          this.props.enqueueSnackbar(error.response.data.message, {
            variant: "error",
          });
        }

        console.log(error);
      });
  }

  assignUser() {
    this.setState({
      ready: false,
    });

    const { user } = this.props.userContext;
    performRequest(
      "POST",
      assignUserRoute,
      {
        user_id: this.state.searchUserResult.id,
        player_id: this.props.player.id,
        participant_id: this.props.participant.id,
      },
      true,
      user
    )
      .then((response) => {
        this.props.enqueueSnackbar(response.data.message, {
          variant: "success",
        });
        this.props.onSave();
      })
      .catch((error) => {
        this.setState({
          ready: true,
        });
        console.log(error);
      });
  }

  removeAssignedUser() {
    if (!this.state.isAppUser) {
      return null;
    }

    this.setState({
      ready: false,
    });
    const { user } = this.props.userContext;
    performRequest(
      "POST",
      playerUpdateRoute,
      {
        player_id: this.props.player.id,
        playerable_type: null,
        playerable_id: null,
      },
      true,
      user
    )
      .then((response) => {
        this.props.enqueueSnackbar("Cuenta Reva desasociada", {
          variant: "success",
        });
        this.props.onSave();
      })
      .catch((error) => {
        this.setState({
          ready: true,
        });
        this.props.enqueueSnackbar(error.response.data.message, {
          variant: "error",
        });
        console.log(error);
      });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes, player, tournament } = this.props;
    const { user } = this.props.userContext;
    const canSearchUsers = user.permissions["players.search-user.assign"];
    const canChangeTeam = tournament?.sport_slug === "soccer";

    if (!this.state.ready) {
      return (
        <Grid container justifyContent="center" alignItems="center" className={classes.modalContainer}>
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <Grid container className={classes.modalContainer}>
        <Grid style={{ marginBottom: 20 }}>
          <Typography variant="h1">Editar jugador</Typography>
        </Grid>

        <Grid container>
          <Grid
            item
            container
            xs={4}
            justifyContent="center"
            alignItems="center"
            style={{ display: "flex", textAlign: "center" }}
          >
            <Grid item container justifyContent="center" alignItems="center">
              <Avatar className={classes.largeAvatar} key={player.id} src={player.full_profile_pic_url}></Avatar>
            </Grid>
          </Grid>
          <Grid container item xs={8} spacing={8}>
            <Grid item xs={12} spacing={1} container>
              <Typography variant="h2">Datos</Typography>

              <Grid item xs={12} justifyContent="flex-start">
                <TextField
                  variant="standard"
                  label="Nombre"
                  value={this.state.name}
                  onChange={this.handleChange("name")}
                  disabled={this.state.isAppUser}
                />
              </Grid>
              <Grid item xs={12} justifyContent="flex-start">
                <TextField
                  variant="standard"
                  label="Teléfono"
                  value={this.state.phoneNumber}
                  onChange={this.handleChange("phoneNumber")}
                  disabled={this.state.isAppUser}
                />
              </Grid>
              <Grid item xs={12} justifyContent="flex-start">
                <TextField
                  variant="standard"
                  label="Documento"
                  value={this.state.documentNumber}
                  onChange={this.handleChange("documentNumber")}
                  disabled={this.state.isAppUser}
                />
              </Grid>
              {canChangeTeam && (
                <Grid item xs={12} justifyContent="flex-start">
                  <FormControl variant="standard">
                    <InputLabel id="team-select-label">Equipo</InputLabel>
                    <Select
                      variant="standard"
                      labelId="team-select-label"
                      id="team-select"
                      label="Equipo"
                      value={this.state.selectedTeamId}
                      onChange={this.handleChange("selectedTeamId")}
                    >
                      {this.props.participants.map((participant) => {
                        return (
                          <MenuItem key={participant.id} value={participant.team_id}>
                            {participant.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            {canSearchUsers && (
              <Grid item xs={12} container spacing={1}>
                <Typography variant="h2">Cuenta Reva</Typography>
                <br></br>
                <small>
                  <i>
                    Ingresa el número de teléfono o documento para buscar a un usuario de Reva y asignarlo a este
                    jugador
                  </i>
                </small>
                <Grid item xs={12} container>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      label="Teléfono"
                      value={this.state.userPhoneNumber}
                      onChange={this.handleChange("userPhoneNumber")}
                      placeholder="+595980456789"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      label="Documento"
                      value={this.state.userDocument}
                      onChange={this.handleChange("userDocument")}
                      placeholder="Cédula/Documento de identidad"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="Buscar usuario" onClick={this.searchUser} edge="end" size="large">
                              <SearchIcon></SearchIcon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                {this.state.searchingUser && (
                  <Grid item xs={12} container>
                    <CircularProgress size={12} />
                  </Grid>
                )}

                {this.state.searchEmpty && (
                  <Grid item xs={12} container>
                    <i>No se ha encontrado un usuario con este número de teléfono o documento</i>
                  </Grid>
                )}
                {this.state.searchUserResult && (
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="space-evenly"
                    alignContent="center"
                    className={classes.searchUserContainer}
                  >
                    <Grid item container alignContent="center" xs={6}>
                      <Grid
                        item
                        xs={3}
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{ display: "flex", textAlign: "center" }}
                      >
                        <Avatar src={this.state.searchUserResult.full_profile_pic_url}></Avatar>
                      </Grid>
                      <Grid item xs={9}>
                        {this.state.searchUserResult.full_name}
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="outlined" color="primary" onClick={this.assignUser}>
                        Asignar usuario
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between" item xs={12} style={{ marginTop: 40 }}>
          {canSearchUsers && this.state.isAppUser && (
            <Grid>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  this.setState({
                    deleteAlertOpen: true,
                  });
                }}
              >
                Desasociar Cuenta Reva
              </Button>
            </Grid>
          )}
          {!this.state.isAppUser && !this.state.searchUserResult && (
            <Grid>
              <Button variant="contained" color="primary" onClick={this.savePlayer.bind(this)}>
                Guardar
              </Button>
            </Grid>
          )}
        </Grid>
        <AlertDialog
          hideButton
          isOpen={this.state.deleteAlertOpen}
          onConfirm={() => this.setState({ deleteAlertOpen: false }, this.removeAssignedUser)}
          onCancel={() => this.setState({ deleteAlertOpen: false })}
          alertBody="¿Estás seguro que desasociar la cuenta de este jugador? Ya no podrá seguir los resultados del torneo desde su app"
          confirmButtonTitle="Si, estoy seguro"
          cancelButtonTitle="Volver atrás"
        />
      </Grid>
    );
  }
}

export default withSnackbar(withUserContext(withStyles(styles)(EditPlayerModal)));
