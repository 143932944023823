export default {
  pending: {
    show_group_helper: true,
    show_categories: true,
    text: "Pendiente",
  },
  "group-stage-underway": {
    show_group_helper: true,
    show_categories: false,
    text: "En etapa de grupos",
  },
  "final-stage-pending": {
    show_group_helper: true,
    show_categories: false,
    text: "Pendiente etapa final",
  },
  underway: {
    show_group_helper: true,
    show_categories: false,
    text: "En curso",
  },
  "pending-finished": {
    show_group_helper: true,
    show_categories: false,
    text: "Pendiente para terminar",
  },
  finished: {
    show_group_helper: true,
    show_categories: false,
    text: "Terminado",
  },
};

export const TOURNAMENT_PENDING = "pending";
export const TOURNAMENT_UNDERWAY = "underway";
export const TOURNAMENT_GROUP_STAGE_UNDERWAY = "group-stage-underway";
export const TOURNAMENT_FINAL_STAGE_PENDING = 'final-stage-pending';
export const TOURNAMENT_FINISHED = "finished";
export const TOURNAMENT_PENDING_FINISHED = 'pending-finished';
