import React, { useState } from "react";
import { ReactSortable } from "react-sortablejs";

// Functional Component
const SortableList = ({
  key,
  renderItem,
  items = [],
  onChange = null,
  groupName = "list",
  disabled = false,
  listComponent = "div",
  handleClassName = null,
}) => {
  const newItems = [...items];
  const [state, setState] = useState(newItems);

  return (
    <div>
      <ReactSortable
        // Sortable options (https://github.com/SortableJS/Sortable#options)
        list={state}
        setList={(newList) => {
          setState(newList);
          if (onChange) {
            onChange(newList);
          }
        }}
        key={key}
        disabled={disabled}
        handle={handleClassName}
        // [Optional] A tag or react component to specify the wrapping element. Defaults to "div".
        // In a case of a react component it is required to has children in the component
        // and pass it down.
        tag={listComponent}
      >
        {newItems.map((val, i) => (
          <div key={val.id}>{renderItem(val, i)}</div>
        ))}
      </ReactSortable>
    </div>
  );
};

export default SortableList;
