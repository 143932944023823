import React, { Component } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { withStyles } from "@mui/styles";
import CompetitionForm from "./CompetitionForm";
import performRequest from "../../../network/perform-request";
import { competitionsShowRoute } from "../../../network/api-routes";
const styles = (theme) => ({
  container: {},
  title: {
    fontWeight: 500,
    fontSize: 48,
    marginBottom: theme.spacing(2),
  },
});

class EditCompetition extends Component {
  constructor(props) {
    super();

    this.state = {
      id: props.match.params.id,
      ready: false,
      competition: null,
    };
  }

  componentDidMount() {
    this.fetch(this.props.userContext.user);
  }

  fetch(user) {
    performRequest("POST", competitionsShowRoute, { competition_uuid: this.state.id }, true, user)
      .then((response) => {
        this.setState({
          competition: response.data.data,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onSubmitResponse(competition) {
    this.props.history.push(`/evento/${competition.uuid_}`);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container} justifyContent="center">
        {!this.state.ready && <CircularProgress></CircularProgress>}
        {this.state.ready && this.state.competition && (
          <Grid item xs={12} lg={5}>
            <CompetitionForm competition={this.state.competition} onSubmitResponse={this.onSubmitResponse.bind(this)} />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(EditCompetition);
