import React from "react";
import PropTypes from "prop-types";
import performRequest from "../../../network/perform-request";
import { signInRoute, registerRoute, facebookLoginRoute } from "../../../network/api-routes";
import UserContext from "../../../context/UserContext";
import { Typography, Button, FormControl, Input, InputLabel } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      allowsEmails: true,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleSubmit(event, changeUser) {
    event.preventDefault();

    performRequest(
      "POST",
      registerRoute,
      {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        allows_send_emails: this.state.allowsEmails,
        email: this.state.email,
        password: this.state.password,
      },
      false
    )
      .then((response) => {
        //Login the user
        performRequest(
          "POST",
          signInRoute,
          {
            email: this.state.email,
            password: this.state.password,
          },
          false
        )
          .then((response) => {
            const user = response.data.data;
            const token = response.data.meta.token;
            changeUser(user, token);
            this.props.successCallback();
          })
          .catch((error) => {
            //TODO: handle error
            console.log(error);
          });

        this.props.successCallback();
      })
      .catch((error) => {
        this.setState({
          error: true,
          errorMessage: error.response.data.message,
        });
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  responseFacebook(response, changeUser) {
    //Log in user with token
    performRequest(
      "POST",
      facebookLoginRoute,
      {
        fbtoken: response.accessToken,
      },
      false
    )
      .then((response) => {
        console.log(response);
        const user = response.data.data;
        const token = response.data.meta.token;
        changeUser(user, token);
        this.props.successCallback();
      })
      .catch((error) => {
        //TODO: handle error
        console.log(error);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={classes.container}>
            <Typography variant="caption">o crea una cuenta con tu email</Typography>
            <form
              onSubmit={(event) => {
                this.handleSubmit(event, userContext.changeUser);
              }}
              className={classes.form}
            >
              <FormControl variant="standard" margin="normal" required fullWidth>
                <InputLabel htmlFor="firstName">Nombre</InputLabel>
                <Input id="firstName" name="firstName" value={this.state.firstName} onChange={this.handleInputChange} />
              </FormControl>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <InputLabel htmlFor="lastName">Apellido</InputLabel>
                <Input id="lastName" name="lastName" value={this.state.lastName} onChange={this.handleInputChange} />
              </FormControl>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </FormControl>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Contraseña</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                />
              </FormControl>
              {this.state.error && (
                <div className={classes.errorMessage}>
                  <Typography color="inherit">{this.state.errorMessage}</Typography>
                </div>
              )}
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Registrarme
              </Button>
            </form>
            <Typography
              variant="caption"
              onClick={() => {
                this.props.openSignIn();
              }}
              style={{ marginTop: "30px", cursor: "pointer" }}
            >
              ¿Ya tienes una cuenta? Inicia sesión
            </Typography>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

RegisterForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RegisterForm);
