import React from "react";
import { Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import ClassificationForm from "./ClassificationForm";
const styles = (theme) => ({
  container: {
    padding: `${theme.spacing(6)} ${theme.spacing(12)}`,
  },
  title: {
    fontWeight: 500,
    fontSize: 48,
    marginBottom: theme.spacing(8),
  },
});

const onSubmitResponse = (classification, props) => {
  console.log("classification is", classification);
  props.history.push(`/classification/${classification.uuid_}`);
};

const CreateClassification = (props) => {
  const { classes } = props;
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h1" className={classes.title}>
          Nueva clasificación
        </Typography>
      </Grid>
      <ClassificationForm onSubmitResponse={(classification) => onSubmitResponse(classification, props)} />
    </Grid>
  );
};

export default withStyles(styles)(CreateClassification);
