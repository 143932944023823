import React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import modalities from "../../../constants/modalities";

const countries = require("../../../constants/ISO3166-1.alpha2.json");

const filter = createFilterOptions();

export default function CategoryAutocomplete(props) {
  const [value, setValue] = React.useState(props.defaultValue || null);
  const [open, toggleOpen] = React.useState(false);
  let options = props.categories;

  const onCategoryAdded = props.onCategoryAdded;
  const onCategorySelected = props.onCategorySelected;

  //sort categories by ID
  options.sort((a, b) => {
    return a.id - b.id;
  });

  let officialCategories = [];

  //create an array of all official categories
  options.forEach((option) => {
    if (option.is_official) {
      const country = countries[option.country] ? countries[option.country] : option.country;
      option.group = `${country} - ${option.official_group}`;
      option.index = option.official_index;
      officialCategories.push(option);
    }
  });

  //sort by group, and then by index
  officialCategories.sort(function (a, b) {
    return a.group.localeCompare(b.group) || a.index - b.index;
  });

  //filter duplicates
  officialCategories = officialCategories.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);

  //create an array of custom categories
  let nonOfficialCategories = [];
  options.forEach((option) => {
    if (!option.is_official) {
      option.group = "Tus categorías";
      option.index = "999zzz";
      nonOfficialCategories.push(option);
    }
  });

  //merge official and non-official categories
  options = [...nonOfficialCategories, ...officialCategories];

  const handleClose = () => {
    setDialogValue({
      name: "",
      modality: modalities[0].slug,
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
    modality: modalities[0].slug,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const value = {
      name: dialogValue.name,
      modality: dialogValue.modality,
    };
    setValue(value);
    onCategoryAdded(value);

    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string" || (newValue && newValue.addNew)) {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue.addNew ? "" : newValue,
                modality: modalities[0].slug,
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue,
              modality: modalities[0].slug,
            });
          } else {
            setValue(newValue);
            onCategorySelected(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          filtered.unshift({
            addNew: true,
            inputValue: params.inputValue,
            group: "Tus categorías",
            name: `Agregar ${params.inputValue ? `"${params.inputValue}"` : "nueva categoría"}`,
          });

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={options}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        groupBy={(option) => option.group}
        selectOnFocus
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        style={{ width: "auto" }}
        freeSolo
        renderInput={(params) => <TextField {...params} label="Categoría" variant="outlined" />}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="md" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Agregar una nueva categoría</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="standard">
                <TextField
                  variant="standard"
                  autoFocus
                  size="small"
                  id="name"
                  value={dialogValue.name}
                  onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
                  label="Nombre"
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard">
                <InputLabel shrink id="label-modality">
                  Modalidad
                </InputLabel>
                <Select
                  variant="standard"
                  labelId="label-modality"
                  id="modality"
                  value={dialogValue.modality}
                  onChange={(event) =>
                    setDialogValue({
                      ...dialogValue,
                      modality: event.target.value,
                    })
                  }
                  style={{ width: "100%" }}
                >
                  {modalities.map((modality) => {
                    return (
                      <MenuItem key={modality.slug} value={modality.slug}>
                        {modality.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
