import React, { useState, useEffect, useMemo, useCallback } from "react";
import { TextField, Grid, CircularProgress, Typography, Divider, Card } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";

import { Autocomplete } from "@mui/material";
import { withSnackbar } from "notistack";
import withUserContext from "../../hoc/withUserContext";
import performRequest from "network/perform-request";
import { classificationsIndexRoute } from "network/api-routes";
import Image from "../../common/Image";

const styles = (theme) => ({
  container: {
    margin: `${theme.spacing(6)} ${theme.spacing(12)}`,
  },
  title: {
    fontWeight: 500,
    fontSize: 36,
    color: theme.palette.text.primary,
  },
  gridItem: {
    display: "flex",
  },
  rankingBanner: {
    display: "block",
    width: "100%",
    height: 200,
    objectFit: "cover",
    position: "relative",
  },
});

const ClassificationsIndex = ({ classes, userContext, enqueueSnackbar, archived }) => {
  const [rankings, setRankings] = useState([]);
  const [state, setState] = useState({
    nameFilter: "",
    sportFilter: "",
    categoryFilter: "",
    selectedOrganization: null,
    organizations: [],
    ready: false,
  });

  const setNewState = useCallback(
    (prop, value) => {
      setState((prevState) => ({ ...prevState, [prop]: value }));
    },
    [setState]
  );

  useEffect(() => {
    const getRankings = (organizationID) => {
      const data = organizationID ? { organization_id: organizationID, archived: archived } : { archived: archived };

      performRequest("POST", classificationsIndexRoute, data, userContext.user)
        .then((response) => {
          console.log("classificationsIndexRoute response.data", response);
          if (response.data) {
            setRankings(response.data);
            if (response.data.organizations) {
              setNewState("organizations", response.data.organizations);
            }
            if (response.data.selected_organization) {
              setNewState("selectedOrganization", response.data.selected_organization);
            }
            setNewState("ready", true);
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.message, { variant: "error" });
          setNewState("ready", true);
        });
    };

    getRankings();
  }, [archived, enqueueSnackbar, setNewState, userContext.user]);

  const names = Array.isArray(rankings) && rankings.length > 0 ? [...new Set(rankings.map((item) => item.name))] : [];
  const sports =
    Array.isArray(rankings) && rankings.length > 0 ? [...new Set(rankings.map((item) => item.sport_name))] : [];
  const categories =
    Array.isArray(rankings) && rankings.length > 0 ? [...new Set(rankings.map((item) => item.category_name))] : [];

  const filteredRankings = useMemo(() => {
    return Array.isArray(rankings)
      ? rankings.filter((ranking) => {
          return (
            (!state.sportFilter || ranking.sport_name.toLowerCase().includes(state.sportFilter.toLowerCase())) &&
            (!state.categoryFilter ||
              ranking.category_name.toLowerCase().includes(state.categoryFilter.toLowerCase())) &&
            (!state.nameFilter || ranking.name.toLowerCase().includes(state.nameFilter.toLowerCase()))
          );
        })
      : [];
  }, [rankings, state.categoryFilter, state.nameFilter, state.sportFilter]);

  if (!state.ready) {
    return (
      <Grid item container justifyContent="center" style={{ paddingTop: 50 }}>
        <CircularProgress size={24}></CircularProgress>
      </Grid>
    );
  }

  console.log("filteredRankings", filteredRankings);

  return (
    <Grid container>
      <Grid className={classes.container} item xs={12} textAlign="center">
        <Typography variant="h1" className={classes.title}>
          {archived ? "Rankings Archivados" : "Ranking"}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider light />
      </Grid>

      <Grid container spacing={4} className={classes.container} id="filters">
        <Grid item xs={4}>
          <Autocomplete
            options={names}
            value={state.nameFilter}
            onChange={(event, newValue) => setNewState("nameFilter", newValue)}
            renderInput={(params) => <TextField variant="standard" {...params} label="Nombre" />}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={sports}
            value={state.sportFilter}
            onChange={(event, newValue) => setNewState("sportFilter", newValue)}
            renderInput={(params) => <TextField variant="standard" {...params} label="Deporte" />}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={categories}
            value={state.categoryFilter}
            onChange={(event, newValue) => setNewState("categoryFilter", newValue)}
            renderInput={(params) => <TextField variant="standard" {...params} label="Categoría" />}
          />
        </Grid>
        {filteredRankings.length > 0 && state.ready ? (
          <Grid container item xs={12} spacing={2} style={{ paddingTop: 50 }}>
            {filteredRankings.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={item.id} className={classes.gridItem}>
                <Link to={`/classification/${item.uuid_}`} style={{ display: "flex", flex: 1 }}>
                  <Card style={{ flex: 1 }}>
                    <Image className={classes.rankingBanner} src={`${item.full_cover_url}`} alt={item.name} />
                    <Grid alignItems="center" style={{ margin: 10 }}>
                      <Typography align="center">{item.name}</Typography>
                      <Typography align="center" style={{ marginTop: 5 }}>
                        {item.description}
                      </Typography>
                    </Grid>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container justifyContent="center" className={classes.container}>
            {state.sportFilter === "" && state.categoryFilter === "" && state.nameFilter === "" ? (
              <Typography variant="h4" className={classes.title} style={{ marginTop: 20 }}>
                {"No hay rankings que coincidan con los filtros"}
              </Typography>
            ) : (
              <Typography variant="h4" className={classes.title} style={{ marginTop: 20 }}>
                {"¡Aún no tienes ningun ranking! Crea uno con el botón de arriba"}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withSnackbar(withUserContext(withStyles(styles)(ClassificationsIndex)));
