import React, { Component } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  CircularProgress,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Autocomplete } from "@mui/material";
import { withRouter } from "react-router-dom";
import SubscriptionModal from "components/common/SubscriptionModal";
import moment from "moment";
import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import { es } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { red } from "@mui/material/colors";
import {
  userEstablishmentsRoute,
  competitionsCreateRoute,
  competitionsUpdateRoute,
  customersIndexRoute,
  subscriptionsIndexRoute,
  userManagedEstablishmentsRoute,
} from "../../../network/api-routes";
import withUserContext from "../../hoc/withUserContext";
import performRequest from "../../../network/perform-request";
import { withSnackbar } from "notistack";
import { isNull } from "lodash";

const styles = (theme) => ({
  container: {
    // margin: theme.spacing(8),
  },
  title: {
    fontWeight: 500,
    fontSize: 48,
    color: theme.palette.text.secondary,
  },
  sectionContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    marginBottom: theme.spacing(8),
    boxShadow: "none",
  },
  sectionTitle: {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 14,
  },
  sectionTitleContainer: {
    backgroundColor: theme.palette.action.hover,
    height: 46,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  sectionBodyContainer: {
    padding: theme.spacing(2),
  },
  formContainer: {
    // maxWidth: "60%",
    // marginTop: theme.spacing.unit * 8
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: "1rem",
  },
  input: {
    width: "100%",
  },
  formRow: {
    marginBottom: theme.spacing(4),
  },
  group: {
    margin: `${theme.spacing(1)} 0`,
  },
  createButton: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    fontSize: 20,
    fontWeight: 600,
    textTransform: "none",
  },
  bannerPreview: {
    height: 320,
    width: 180,
    borderRadius: 5,
    objectFit: "cover",
    marginBottom: 5,
  },
  coverPreview: {
    height: 180,
    width: 320,
    borderRadius: 5,
    objectFit: "cover",
    marginBottom: 5,
  },
  qrPreview: {
    height: 100,
    width: 100,
    maxHeight: 100,
    maxWidth: 100,
    borderRadius: 5,
    objectFit: "contain",
    marginVertical: 5,
  },
  dangerButton: {
    backgroundColor: red[500],
    color: "#FFFFFF",
  },
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
});

const Section = (props) => {
  const { classes } = props;

  return (
    <Paper className={classes.sectionContainer}>
      <Grid container>
        <Grid item xs={12} className={classes.sectionTitleContainer}>
          <Typography variant="h1" className={classes.sectionTitle}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.sectionBodyContainer}>
          {props.children}
        </Grid>
      </Grid>
    </Paper>
  );
};

const FormRow = (props) => {
  const { classes } = props;

  return (
    <Grid container className={classes.formRow} alignItems="center">
      <Grid item xs={5}>
        <Typography variant="h3" className={classes.label}>
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {props.children}
      </Grid>
    </Grid>
  );
};

class CompetitionForm extends Component {
  constructor(props) {
    super(props);
    const { competition } = props;
    let selectedCustomer = null;
    let selectedEstablishments = [];

    let customerDisabled = false;
    if (competition) {
      selectedCustomer = competition.customer && competition.customer.data;
      if (selectedCustomer) {
        customerDisabled = true;
      }
      selectedEstablishments = competition.establishments.data;
    }

    this.state = {
      name: competition ? competition.name : "",
      description: competition ? competition.description : "",
      teamRequired: competition ? competition.team_required : false,
      banner: null,
      bannerUrl: competition ? competition.full_banner_url : null,
      cover: null,
      coverUrl: competition ? competition.full_cover_url : null,
      regulationsUrl: competition ? competition.full_regulations_url : null,
      regulations: null,
      qr: null,
      qrUrl: competition ? competition.full_payqr_url : null,
      qrEnabled: competition ? competition.payqr_enabled : false,
      startAt: competition ? competition.start_at : null,
      endAt: competition ? competition.end_at : null,
      accounts: [],
      customers: [],
      selectedAccount:
        competition && competition.account ? competition.account.data : null,
      signupsExpire: competition ? competition.signups_expires : false,
      expiresAfter: competition ? competition.signups_expires_after : "",
      establishments: [],
      customerDisabled,
      selectedEstablishments,
      onlyOnlinePayment: competition ? competition.only_online_payment : false,
      acceptOnlinePayments: competition ? competition.online_payments : false,
      organizer: competition ? competition.organizer : "",
      organizerPhone: competition ? competition.organizer_phone : "",

      // Social
      whatsapp: competition ? competition.whatsapp_group_url || "" : "",
      discord: competition ? competition.discord_url || "" : "",
      isSubscriptionModalOpen: false,
      subscriptionsPlans: [],
      selectedCustomer: competition?.customer
        ? competition.customer.data
        : null,
      selectedPlan: competition?.subscription ? competition.subscription : null,
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleBannerChange = this.handleBannerChange.bind(this);
    this.handleRegulationsChange = this.handleRegulationsChange.bind(this);
  }

  componentDidMount() {
    this.getCustomers();
  }

  handleOpenModal = () => {
    this.setState({ isSubscriptionModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isSubscriptionModalOpen: false, submiting: false });
  };

  handleSubmit(event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    this.setState(
      {
        submiting: true,
      },
      () => {
        const { competition, createCompetition } = this.props;
        const user = this.props.userContext.user;

        let formData = new FormData();
        formData.append("name", this.state.name);
        formData.append(
          "description",
          this.state.description ? this.state.description : ""
        );

        formData.append(
          "organizer",
          this.state.organizer ? this.state.organizer : ""
        );

        formData.append(
          "whatsapp_group_url",
          this.state.whatsapp ? this.state.whatsapp : ""
        );

        formData.append(
          "discord_url",
          this.state.discord ? this.state.discord : ""
        );

        formData.append(
          "organizer_phone",
          this.state.organizerPhone ? this.state.organizerPhone : ""
        );
        if (this.state.banner) {
          formData.append(
            "banner",
            this.state.banner,
            `${this.state.name}-banner.jpg`
          );
        }

        if (this.state.cover) {
          formData.append(
            "cover",
            this.state.cover,
            `${this.state.name}-cover.jpg`
          );
        }

        if (this.state.qr) {
          formData.append(
            "payqr",
            this.state.qr,
            `${this.state.name}-payqr.jpg`
          );
        }

        if (this.state.regulations) {
          formData.append(
            "regulations",
            this.state.regulations,
            `${this.state.name}-regulations.pdf`
          );
        }
        if (this.state.startAt) {
          formData.append(
            "start_at",
            moment(this.state.startAt).format("YYYY-MM-DD HH:mm:ss")
          );
        }
        if (this.state.endAt) {
          formData.append(
            "end_at",
            moment(this.state.endAt).format("YYYY-MM-DD HH:mm:ss")
          );
        }

        if (this.state.selectedCustomer && this.state.selectedAccount) {
          formData.append("customer_id", this.state.selectedCustomer.id);
          formData.append("account_id", this.state.selectedAccount.id);
          if (this.state.acceptOnlinePayments) {
            formData.append("online_payments", JSON.stringify(true));
          } else {
            formData.append("online_payments", JSON.stringify(false));
          }

          if (this.state.onlyOnlinePayment) {
            formData.append("only_online_payment", JSON.stringify(true));
          } else {
            formData.append("only_online_payment", JSON.stringify(false));
          }
        } else {
          this.props.enqueueSnackbar(
            "Debes seleccionar un cliente y una cuenta",
            {
              variant: "error",
            }
          );
          this.setState({ submiting: false });
          return;
        }
        formData.append("payqr_enabled", JSON.stringify(this.state.qrEnabled));

        formData.append(
          "signups_expires",
          JSON.stringify(this.state.signupsExpire)
        );
        formData.append(
          "signups_expires_after",
          this.state.signupsExpire ? this.state.expiresAfter : ""
        );

        if (this.state.selectedPlan) {
          formData.append("price_id", this.state.selectedPlan);
        }

        const headers = { "Content-Type": "multipart/form-data" };
        const route = competition
          ? competitionsUpdateRoute
          : competitionsCreateRoute;

        if (createCompetition) {
          this.handleFormSubmit(formData, route, user, headers);
        } else {
          formData.append("competition_id", competition.id);
          this.handleFormSubmit(formData, route, user, headers);
        }
      }
    );
  }

  handleFormSubmit = (formData, route, user, headers) => {
    performRequest("POST", route, formData, true, user, null, headers)
      .then((response) => {
        const competition = response.data.data;
        this.setState({ submiting: false });
        this.props.onSubmitResponse(competition);
      })
      .catch((error) => {
        this.setState({ submiting: false });
        this.props.enqueueSnackbar(
          error?.response?.data?.message ||
            "Ha ocurrido un error. Favor revisa tus datos",
          {
            variant: "error",
          }
        );
        console.log(error);
      });
  };

  handleChange = (name) => (event) => {
    switch (name) {
      case "description":
        if (event.target.value.length <= 500) {
          this.setState({
            description: event.target.value,
          });
        }
        break;
      default:
        this.setState({
          [name]: event.target.value,
        });
        break;
    }
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleBannerChange(event) {
    const fileSize = event.target.files[0].size / 1024 / 1024;
    if (fileSize > 1) {
      this.props.enqueueSnackbar("El archivo es demasiado grande", {
        variant: "error",
      });
      event.preventDefault();
      return false;
    }

    this.setState({
      banner: event.target.files[0],
      bannerUrl: URL.createObjectURL(event.target.files[0]),
    });
  }

  handleCoverChange(event) {
    const fileSize = event.target.files[0].size / 1024 / 1024;
    if (fileSize > 1) {
      this.props.enqueueSnackbar("El archivo es demasiado grande", {
        variant: "error",
      });
      event.preventDefault();
      return false;
    }

    this.setState({
      cover: event.target.files[0],
      coverUrl: URL.createObjectURL(event.target.files[0]),
    });
  }

  handlePayQrChange(event) {
    const fileSize = event.target.files[0].size / 1024 / 1024;
    if (fileSize > 1) {
      this.props.enqueueSnackbar("El archivo es demasiado grande", {
        variant: "error",
      });
      event.preventDefault();
      return false;
    }

    this.setState({
      qr: event.target.files[0],
      qrUrl: URL.createObjectURL(event.target.files[0]),
    });
  }

  handleRegulationsChange(event) {
    const fileSize = event.target.files[0].size / 1024 / 1024;
    if (fileSize > 1) {
      this.props.enqueueSnackbar("El archivo es demasiado grande", {
        variant: "error",
      });
      event.preventDefault();

      return false;
    }
    this.setState({
      regulations: event.target.files[0],
    });
  }

  handleDateChange(date) {
    this.setState({
      startAt: date,
    });
  }

  handleEndDateChange(date) {
    this.setState({
      endAt: date,
    });
  }

  getSubscriptionPlans() {
    const { selectedCustomer } = this.state;
    console.log("selected customer", selectedCustomer);
    if (!selectedCustomer) return;
    const { user } = this.props;
    performRequest(
      "POST",
      subscriptionsIndexRoute,
      { customer_id: selectedCustomer.id, product_type: "App\\Competition" },
      true,
      user,
      null
    )
      .then((response) => {
        const plans = response.data;
        console.log("plans are", response);
        this.setState({ subscriptionsPlans: plans });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error?.response?.data?.message ||
            "Ha ocurrido un error. Favor revisa tus datos",
          {
            variant: "error",
          }
        );
        console.log(error);
      });
  }

  getCustomers() {
    const { user } = this.props;

    performRequest("POST", customersIndexRoute, {}, true, user)
      .then((response) => {
        console.log("customers index route response", response);
        const customers = response.data.customers;
        this.setState({ customers });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getUserEstablishments() {
    const { user } = this.props;

    performRequest("POST", userManagedEstablishmentsRoute, {}, true, user)
      .then((response) => {
        const establishments = response.data.data;
        this.setState({ establishments });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //TODO: This method is currently unused. Later, we can use it to change the subscription plan of a competition
  /* subscribeToPlan(competition) {
    const { selectedPlan, selectedCustomer } = this.state;
    const { user } = this.props;
    performRequest(
      "POST",
      subscribeToCompetitionServiceRoute,
      {
        pricing_id: selectedPlan,
        competition_id: competition.id,
        customer_id: selectedCustomer.id,
      },
      true,
      user
    )
      .then((response) => {
        console.log("subscription response", response);
        this.props.onSubmitResponse(competition);
      })
      .catch((error) => {
        console.log(error);
      });
  } */

  render() {
    const { classes, competition } = this.props;
    return (
      <Grid item xs={12} container className={classes.formContainer}>
        <form
          onSubmit={(event) => {
            this.handleSubmit(event);
          }}
          style={{ width: "100%" }}
        >
          <Section title="Información básica" {...this.props}>
            <FormRow label="Nombre del evento*" {...this.props}>
              <FormControl
                variant="standard"
                margin="normal"
                required
                fullWidth
              >
                <TextField
                  variant="standard"
                  value={this.state.name}
                  onChange={this.handleChange("name")}
                  className={classes.input}
                  placeholder={"Copa Deportiva"}
                  required
                />
              </FormControl>
            </FormRow>
            <FormRow label="Descripción" {...this.props}>
              <TextField
                variant="standard"
                multiline
                maxRows={6}
                value={this.state.description || ""}
                onChange={this.handleChange("description")}
                className={classes.input}
              />
            </FormRow>
            <FormRow label="Organizador" {...this.props}>
              <TextField
                variant="standard"
                value={this.state.organizer || ""}
                onChange={this.handleChange("organizer")}
                className={classes.input}
                placeholder="Conmebol"
                required
              />
            </FormRow>

            <FormRow label="Teléfono del organizador" {...this.props}>
              <TextField
                variant="standard"
                value={this.state.organizerPhone || ""}
                onChange={this.handleChange("organizerPhone")}
                className={classes.input}
                placeholder="+595991xxxxxx"
              />
            </FormRow>

            <FormRow label="Poster del torneo" {...this.props}>
              {this.state.bannerUrl && (
                <Grid container justifyContent="center">
                  <img
                    className={classes.bannerPreview}
                    src={this.state.bannerUrl}
                    alt="Imagen no válida"
                  ></img>
                </Grid>
              )}
              <input
                accept="image/jpg"
                className={classes.input}
                type="file"
                onChange={this.handleBannerChange.bind(this)}
              />
              <small>
                <i>Imagen en formato JPG. Tamaño máximo 800 KB</i>
              </small>
            </FormRow>

            <FormRow label="Cover del torneo" {...this.props}>
              {this.state.coverUrl && (
                <Grid container justifyContent="center">
                  <img
                    className={classes.coverPreview}
                    src={this.state.coverUrl}
                    alt="Imagen no válida"
                  ></img>
                </Grid>
              )}
              <input
                accept="image/jpg"
                className={classes.input}
                type="file"
                onChange={this.handleCoverChange.bind(this)}
              />
              <small>
                <i>Imagen en formato JPG. Tamaño máximo 800 KB</i>
              </small>
            </FormRow>

            <FormRow label="Reglamento" {...this.props}>
              {this.state.regulationsUrl && (
                <a
                  href={this.state.regulationsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver reglamento
                </a>
              )}
              <input
                accept="application/pdf"
                className={classes.input}
                type="file"
                onChange={this.handleRegulationsChange}
              />
              <small>
                <i>Reglamento en PDF. Tamaño máximo 1MB</i>
              </small>
            </FormRow>
          </Section>

          <Section title="Información del evento" {...this.props}>
            <FormRow label="Fecha de inicio*" {...this.props}>
              <DatePicker
                customInput={<TextField variant="standard" />}
                clearButtonTitle="Borrar"
                selected={
                  this.state.startAt ? new Date(this.state.startAt) : null
                }
                onChange={this.handleDateChange}
                minDate={subDays(new Date(), 0)}
                locale={es}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={5}
                dateFormat="dd/MM/yyyy HH:mm"
                timeCaption="Hora"
                isClearable={false}
                required={true}
              />
            </FormRow>
            <FormRow label="Fecha de fin*" {...this.props}>
              <DatePicker
                customInput={<TextField variant="standard" />}
                clearButtonTitle="Borrar"
                selected={this.state.endAt ? new Date(this.state.endAt) : null}
                onChange={this.handleEndDateChange}
                minDate={subDays(new Date(), 0)}
                locale={es}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={5}
                dateFormat="dd/MM/yyyy HH:mm"
                timeCaption="Hora"
                isClearable={false}
                required={true}
              />
            </FormRow>
          </Section>

          <Section title="Pagos" {...this.props}>
            {this.state.customers.length === 0 && (
              <Grid container justifyContent="center">
                <CircularProgress></CircularProgress>
              </Grid>
            )}
            {this.state.customers.length > 0 && (
              <React.Fragment>
                <FormRow label="Cliente" {...this.props}>
                  <Autocomplete
                    value={this.state.selectedCustomer}
                    options={this.state.customers}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <li {...props}>
                        {/* <Avatar
                            key={option.id}
                            alt={option.name}
                            src={option.accountable.full_logo_url}
                            className={classes.avatar}
                          ></Avatar> */}
                        {option.name}
                      </li>
                    )}
                    onChange={(event, value) => {
                      let newState = {
                        selectedCustomer: value,
                      };
                      if (value) {
                        newState["currencyCode"] = value.currency_code;
                        //if the number of accounts in the customer is 1, we select it by default
                        if (value.accounts.length === 1) {
                          newState["selectedAccount"] = value.accounts[0];
                        }
                      } else {
                        newState["acceptOnlinePayments"] = false;
                        newState["onlyOnlinePayment"] = false;
                      }
                      this.setState(newState, () => {
                        this.getSubscriptionPlans();
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona un cliente"
                        variant="outlined"
                      />
                    )}
                    required={true}
                    disabled={this.state.customerDisabled}
                  />

                  {competition && this.selectedCustomer === null && (
                    <Typography>
                      No puedes cambiar el cliente de un evento ya creado
                    </Typography>
                  )}
                </FormRow>
                <FormRow label="Cuenta" {...this.props}>
                  <Autocomplete
                    value={this.state.selectedAccount}
                    options={
                      this.state.selectedCustomer
                        ? this.state.selectedCustomer.accounts
                        : []
                    }
                    getOptionLabel={(option) =>
                      `[${option.currency_code}] ${option.name}`
                    }
                    renderOption={(props, option) => (
                      <li
                        {...props}
                      >{`[${option.currency_code}] ${option.name}`}</li>
                    )}
                    onChange={(event, value) => {
                      let newState = {
                        selectedAccount: value,
                      };
                      this.setState(newState, () => {
                        this.getSubscriptionPlans();
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona una cuenta donde se registrarán los pagos y cobros"
                        variant="outlined"
                      />
                    )}
                    required={true}
                    disabled={this.state.customerDisabled}
                  />

                  {competition && this.selectedCustomer === null && (
                    <Typography>
                      No puedes asignar una cuenta a un torneo ya creado
                    </Typography>
                  )}
                </FormRow>
                <FormRow label="Opciones de pago" {...this.props}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isNull(this.state.selectedAccount)}
                        checked={this.state.acceptOnlinePayments}
                        onChange={this.handleCheckboxChange(
                          "acceptOnlinePayments"
                        )}
                        value="acceptOnlinePayments"
                      />
                    }
                    label="Acepta pagos online"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          isNull(this.state.selectedCustomer) ||
                          !this.state.acceptOnlinePayments
                        }
                        checked={this.state.onlyOnlinePayment}
                        onChange={this.handleCheckboxChange(
                          "onlyOnlinePayment"
                        )}
                        value="onlyOnlinePayment"
                      />
                    }
                    label="Solo permitir pagos online"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.qrEnabled}
                        onChange={this.handleCheckboxChange("qrEnabled")}
                        value="qrEnabled"
                      />
                    }
                    label="Pagos con QR"
                  />
                  {this.state.qrEnabled && (
                    <FormRow label="QR de pagos" {...this.props}>
                      {this.state.qrUrl && (
                        <Grid container justifyContent="center">
                          <img
                            className={classes.qrPreview}
                            src={this.state.qrUrl}
                            alt="Imagen no válida"
                          ></img>
                        </Grid>
                      )}
                      <input
                        accept="image/jpg"
                        className={classes.input}
                        type="file"
                        onChange={this.handlePayQrChange.bind(this)}
                      />
                      <small>
                        <i>Imagen en formato JPG. Tamaño máximo 800 KB</i>
                      </small>
                    </FormRow>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.signupsExpire}
                        onChange={this.handleCheckboxChange("signupsExpire")}
                        value="signupsExpire"
                      />
                    }
                    label="Inscripciones sin pago expiran luego de un tiempo"
                  />
                </FormRow>
                <FormRow label="Tiempo disponible para pagar" {...this.props}>
                  <Select
                    variant="standard"
                    fullWidth
                    disabled={!this.state.signupsExpire}
                    required={this.state.signupsExpire}
                    value={this.state.expiresAfter}
                    label="Límite de tiempo"
                    onChange={(e) =>
                      this.setState({ expiresAfter: e.target.value })
                    }
                  >
                    <MenuItem value={60}>1 hora</MenuItem>
                    <MenuItem value={120}>2 horas</MenuItem>
                    <MenuItem value={180}>3 horas</MenuItem>
                    <MenuItem value={720}>12 horas</MenuItem>
                    <MenuItem value={1440}>1 día</MenuItem>
                    <MenuItem value={2880}>2 dias</MenuItem>
                  </Select>
                </FormRow>
              </React.Fragment>
            )}
          </Section>

          <Section title="Social" {...this.props}>
            <FormRow label="Grupo de WhatsApp" {...this.props}>
              <TextField
                variant="standard"
                className={classes.input}
                value={this.state.whatsapp}
                onChange={this.handleChange("whatsapp")}
                inputProps={{
                  placeholder: "Link a grupo de WhatsApp del torneo",
                  type: "url",
                }}
              />
            </FormRow>
            <FormRow label="Discord" {...this.props}>
              <TextField
                variant="standard"
                className={classes.input}
                value={this.state.discord}
                onChange={this.handleChange("discord")}
                inputProps={{
                  placeholder: "Link a servidor de Discord del torneo",
                  type: "url",
                }}
              />
            </FormRow>
          </Section>

          <Grid item xs={12} container justifyContent="flex-end">
            <Button
              disabled={this.state.submiting}
              className={`cta ${classes.createButton}`}
              onClick={() => {
                if (!this.state.selectedPlan) {
                  this.handleOpenModal();
                } else {
                  this.handleSubmit(null);
                }
              }}
            >
              {this.state.submiting && <CircularProgress size={24} />}
              {competition ? "Guardar cambios" : "Crear evento"}
            </Button>
          </Grid>
        </form>

        <SubscriptionModal
          open={this.state.isSubscriptionModalOpen}
          onClose={this.handleCloseModal}
          subscriptionPlans={this.state.subscriptionsPlans}
          onSubscribe={(id) => {
            this.setState(
              { selectedPlan: id, isSubscriptionModalOpen: false },
              () => {
                console.log(
                  "the modal should be open?",
                  this.state.isSubscriptionModalOpen
                );
                this.handleSubmit(null);
              }
            );
          }}
        />
      </Grid>
    );
  }
}

export default withSnackbar(
  withRouter(withUserContext(withStyles(styles)(CompetitionForm)))
);
