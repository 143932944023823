import React from "react";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";

import { red } from "@mui/material/colors";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const defaultTheme = {
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: "none",
        },
      },
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       backgroundImage: "unset",
    //     },
    //   },
    // },
  },
  typography: {
    fontFamily: "Ubuntu",
    fontSize: 12,
    h1: {
      fontFamily: "Ubuntu",
      fontWeight: "700",
      fontSize: "1.3rem",
    },
    h2: {
      fontFamily: "Ubuntu",
      fontWeight: "500",
      fontSize: "1.25rem",
    },
    h3: {
      fontFamily: "Ubuntu",
      fontWeight: "500",
      fontSize: "1rem",
    },
    h4: {
      fontFamily: "Ubuntu",
      fontWeight: "500",
      fontSize: "0.75rem",
    },
    h5: {
      fontFamily: "Ubuntu",
      fontWeight: "300",
      fontSize: "1rem",
    },
    h6: {
      fontFamily: "Ubuntu",
      fontWeight: "400",
      fontSize: "0.75rem",
    },
    subtitle1: {
      fontFamily: "Ubuntu",
      fontSize: "0.875rem",
    },
    body2: {
      fontFamily: "Ubuntu",
      fontSize: "0.875rem",
    },
    body1: {
      fontFamily: "Ubuntu",
    },
    caption: {
      fontFamily: "Ubuntu",
      fontSize: "0.75rem",
      fontWeight: "300",
    },
    captionWarning: {
      fontFamily: "Ubuntu",
      fontSize: "0.75rem",
      fontWeight: "300",
      fontColor: red[500],
    },
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      light: "#00cc5b",
      main: "#008A30",
      dark: "#008c5c",
    },
    secondary: {
      light: "#FFA347",
      main: "#FF8000",
      dark: "#CC5200",
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
    text: {
      // primary: "#424242",
    },
    background: {
      default: "#303030",
      paper: "#424242",
    },
    mode: "dark",
  },
};

export default function RevaThemeProvider(props) {
  const [darkMode, setDarkMode] = useLocalStorage("darkMode", true);

  // defaultTheme.palette.mode = darkMode ? "dark" : "light";
  const theme = createTheme(defaultTheme);
  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  const childrenWithProps = React.Children.map(props.children, (child, index) => {
    if (index === 1) {
      return React.cloneElement(child, {
        handleThemeChange,
        darkMode,
      });
    }
    return child;
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{childrenWithProps}</ThemeProvider>
    </StyledEngineProvider>
  );
}
