import React, { Component } from "react";
import { Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },

    justify: "center",
    color: theme.palette.text.secondary,
  },
});

class IconEmptyPage extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.layout}>
        <Grid item container xs={12} justifyContent="center">
          <img alt="placeholder" src="/img/no-data.svg" style={{ height: "20vh", marginBottom: 20 }} />
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Typography variant="h3" color="textSecondary" style={{ textAlign: "center" }}>
            {this.props.text}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(IconEmptyPage);
