import React from "react";
import { Grid, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import UserAvatar from "./UserAvatar";

const styles = (theme) => ({
  cursorPointer: {
    cursor: "pointer",
  },
  linksMdContainer: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(4),
    gap: theme.spacing(2),
  },
});

const LinksTopBar = (props) => {
  const { classes, userContext } = props;
  const ready = userContext.userReady;
  if (!ready) {
    return null;
  }
  const user = userContext.user;

  return (
    <Grid item sx={{ display: { xs: "none", md: "flex" } }} alignItems="center" className={classes.linksMdContainer}>
      {!user && (
        <Button href="/entrar" edge="end" color="inherit">
          Login
        </Button>
      )}
      {user && user.organizations ? (
        <Button href="/classifications" edge="end" color="inherit">
          Rankings
        </Button>
      ) : (
        <Button href="/rankings" edge="end" color="inherit">
          Rankings
        </Button>
      )}
      {/* {!user && (
          <Grid
            item
            className={classes.cursorPointer}
            onClick={() => {
              props.handleRegisterOpen(true);
            }}
          >
            <Typography color="inherit">Registrarse</Typography>
          </Grid>
        )} */}
      {user && <UserAvatar userContext={userContext} {...props} />}
    </Grid>
  );
};

export default withStyles(styles)(LinksTopBar);
