import React from "react";
import { SnackbarProvider } from "notistack";
// In order to pass the dark mode toggle we need to keep pushing the props down
export default function SnackbarWrapper(props) {
  const childrenWithProps = React.Children.map(props.children, (child, index) => {
    return React.cloneElement(child, {
      handleThemeChange: props.handleThemeChange,
      darkMode: props.darkMode,
    });
  });

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {childrenWithProps}
    </SnackbarProvider>
  );
}
