export default [
  {
    sport_id: 1,
    name: 'Fútbol',
    slug: 'soccer'
  },
  {
    sport_id: 2,
    name: 'Tenis',
    slug: 'tennis'
  },
  {
    sport_id: 3,
    name: 'Basket',
    slug: 'basket'
  },
  {
    sport_id: 4,
    name: 'Pádel',
    slug: 'paddle'
  },
  {
    sport_id: 5,
    name: 'Padbol',
    slug: 'padbol'
  },
  {
    sport_id: 6,
    name: 'Squash',
    slug: 'squash'
  },
  {
    sport_id: 7,
    name: 'Vóley',
    slug: 'volley'
  },
  {
    sport_id: 8,
    name: 'Handball',
    slug: 'handball'
  },
  {
    sport_id: 9,
    name: 'Hockey',
    slug: 'hockey'
  },
  {
    sport_id: 10,
    name: 'Futsal',
    slug: 'futsal'
  },
  {
    sport_id: 11,
    name: 'Piki Vóley',
    slug: 'futvoley'
  },
  {
    sport_id: 12,
    name: 'Paintball',
    slug: 'paintball'
  },
  {
    sport_id: 16,
    name: 'Frontón',
    slug: 'fronton'
  },
  {
    sport_id: 18,
    name: 'Beach Tennis',
    slug: 'beach-tennis'
  },
  {
    sport_id: 20,
    name: 'Raquetball',
    slug: 'raquetball'
  },
  {
    sport_id: 21,
    name: 'Wallyball',
    slug: 'wally-ball'
  }
];
