import { Card, Chip, Divider, Grid, Typography } from "@mui/material";
import { SupervisedUserCircleRounded } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import withUserContext from "components/hoc/withUserContext";
import sports from "constants/sports";
import tournamentStates from "constants/tournamentStates";
import tournamentTypes from "constants/tournamentTypes";
import { find } from "lodash";
import moment from "moment";
import { withSnackbar } from "notistack";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  tournamentRow: {
    color: theme.palette.text.primary,
  },
  tournamentContainer: {
    padding: `${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(6)}`,
  },
  tournamentInfo: {
    color: theme.palette.text.primary,
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  icon: {
    color: theme.palette.divider,
  },
  iconLabel: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginBottom: 2,
  },
});

class CompetitionTournament extends PureComponent {
  render() {
    const { classes, tournament } = this.props;
    const status = tournamentStates[tournament.state];
    let state = null;
    if (status) {
      state = status.text;
      if (tournament.state === "pending" && tournament.open_signup) {
        state = `${state} - Inscripciones abiertas`;
      }
    }

    let tournamentType = null;
    if (tournamentTypes[tournament.tournament_type]) {
      tournamentType = tournamentTypes[tournament.tournament_type].name;
      if (tournament.has_group_stage) {
        const playersPerGroup = tournament.players_per_group;
        const advancing = tournament.advancing_players_per_group;
        tournamentType = `Grupos (${playersPerGroup}→${advancing}) a ${tournamentType}`;
      }
    }

    return (
      <Link key={tournament.id} to={`/torneo/${tournament.uuid_}`} className={classes.tournamentRow}>
        <Card className={classes.tournamentContainer}>
          <Grid container alignItems="center" spacing={2}>
            <Grid container justifyContent="space-between">
              <Grid item className={classes.tournamentInfo}>
                <Typography variant="h2" style={{ marginBottom: 5 }}>
                  {tournament.name}
                </Typography>

                {tournamentType && (
                  <Typography variant="caption" className={classes.subtitle}>
                    {tournament.sport_id && find(sports, (sport) => sport.sport_id === tournament.sport_id).name}
                    {tournament.videogame_name && tournament.videogame_name}
                    {" - "} {tournamentType}
                  </Typography>
                )}

                <br></br>

                {tournament.category && (
                  <Typography variant="caption" className={classes.subtitle}>
                    {tournament.category.data.name}
                  </Typography>
                )}
              </Grid>
              <Grid>
                <Chip
                  icon={<SupervisedUserCircleRounded />}
                  label={`${tournament.participants_count || 0} ${
                    tournament.signup_cap ? `/ ${tournament.signup_cap}` : ""
                  }`}
                  variant="outlined"
                  className={classes.participantsChip}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
              <Divider light></Divider>
            </Grid>

            <Grid container style={{ paddingLeft: 0 }} justifyContent="space-between">
              <Grid item container xs={4} justifyContent="flex-start" alignItems="center">
                <Grid item container xs={3} justifyContent="center">
                  <CalendarTodayOutlinedIcon
                    className={classes.icon}
                    style={{ fontSize: 35 }}
                  ></CalendarTodayOutlinedIcon>
                </Grid>
                <Grid item container xs={9} direction="column" alignItems="flex-start">
                  <Grid item className={classes.iconLabel}>
                    CUANDO
                  </Grid>
                  <Grid item>
                    {tournament.start_at && (
                      <Typography variant="subtitle2">
                        {moment(tournament.start_at).locale("es").format("D [de] MMMM YYYY, H:mm")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={4}>
                <Grid item container xs={4} justifyContent="center" alignItems="center"></Grid>
                <Grid item container xs={8} direction="column">
                  <Grid item className={classes.iconLabel}>
                    STATUS
                  </Grid>
                  <Grid item>
                    <Typography>{state}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Link>
    );
  }
}

export default withSnackbar(withUserContext(withStyles(styles)(CompetitionTournament)));
