import React, { Component } from "react";
import performRequest from "network/perform-request";
import {
  competitionsShowRoute,
  userEstablishmentsRoute,
  competitionEstablishmentsAddRoute,
  competitionEstablishmentsDeleteRoute,
  userManagedEstablishmentsRoute,
} from "network/api-routes";
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import "moment/locale/es";
import AlertDialog from "../../common/AlertDialog";
import { Autocomplete } from "@mui/material";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  container: {
    padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
    maxWidth: 1000,
  },
  contentContainer: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    marginTop: 30,
  },
  tournamentContainer: {
    padding: `${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(
      4
    )} ${theme.spacing(6)}`,
  },
  tournamentBanner: {
    display: "block",
    width: 105,
    height: 140,
    objectFit: "cover",
    position: "relative",
    backgroundColor: "gray",
  },
  listItemText: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: 400,
    fontSize: 48,
    color: theme.palette.text.primary,

    // color:
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  icon: {
    color: theme.palette.divider,
  },
  iconLabel: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginBottom: 2,
  },

  createButton: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    fontSize: 20,
    fontWeight: 600,
    textTransform: "none",
  },
  tournamentInfo: {
    color: theme.palette.text.primary,
  },
  progressBarContainer: {
    marginTop: 10,
    borderRadius: 5,
    height: 10,
    width: 200,
    border: `1px solid #E4E5ED`,
    backgroundColor: "white",
  },
  tournamentRow: {
    color: theme.palette.text.primary,
  },
  progressBar: {
    backgroundColor: theme.palette.main,
  },
});

class CompetitionEstablishments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      ready: false,
      users: [],
      openModal: false,
      deleteOpen: false,
      establishmentOptions: [],
      selectedEstablishment: null,
      toDeleteEstablishment: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.fetchUserEstablishments();
  }

  fetch() {
    const { user } = this.props;

    performRequest(
      "POST",
      competitionsShowRoute,
      { competition_uuid: this.state.id },
      true,
      user
    )
      .then((response) => {
        const competition = response.data.data;
        this.setState({
          competition,
          establishments: competition.establishments.data,
          ready: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchUserEstablishments() {
    const { user } = this.props;

    performRequest("POST", userManagedEstablishmentsRoute, {}, true, user)
      .then((response) => {
        const establishmentOptions = response.data.data;
        this.setState({ establishmentOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  reset() {
    this.setState({
      selectedEstablishment: null,
      toDeleteEstablishment: null,
    });
  }

  submit() {
    const { user } = this.props.userContext;
    this.handleClose();
    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        competitionEstablishmentsAddRoute,
        {
          competition_uuid: this.state.id,
          establishment_id: this.state.selectedEstablishment.id,
        },
        true,
        user
      )
        .then((response) => {
          this.reset();

          this.fetch();
        })
        .catch((error) => {
          this.reset();
          this.fetch();
        });
    });
  }

  handleClose() {
    this.setState({ openModal: false });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderAddDialog() {
    return (
      <div>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleClose}
          fullWidth
        >
          <DialogTitle>Agregar un local</DialogTitle>
          <DialogContent style={{ padding: 40 }}>
            <Autocomplete
              disableCloseOnSelect={false}
              multiple={false}
              value={this.state.selectedEstablishment}
              options={this.state.establishmentOptions}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  {/* <Avatar
                    key={option.id}
                    alt={option.public_name}
                    src={option.full_logo_url}
                    className={classes.avatar}
                  ></Avatar> */}
                  {option.public_name} - {option.country} (
                  {option.active ? "Activo" : "Inactivo"})
                </li>
              )}
              getOptionLabel={(option) => option.public_name}
              isOptionEqualToValue={(option, value) =>
                option.establishment_id === value.establishment_id
              }
              onChange={(event, value) => {
                this.setState({
                  selectedEstablishment: value,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecciona un local para agregarlo"
                  variant="outlined"
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Volver</Button>
            <Button onClick={this.submit}>Agregar</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  delete() {
    const { user } = this.props.userContext;
    this.handleClose();
    this.setState({ ready: false }, () => {
      performRequest(
        "POST",
        competitionEstablishmentsDeleteRoute,
        {
          competition_uuid: this.state.id,
          establishment_id: this.state.toDeleteEstablishment.id,
        },
        true,
        user
      )
        .then((response) => {
          this.setState(
            {
              selectedUserInvite: null,
              ready: false,
            },
            () => {
              this.fetch();
            }
          );
        })
        .catch((error) => {
          this.fetch();
        });
    });
  }
  renderConfirmDeleteDialog() {
    return (
      <AlertDialog
        hideButton
        isOpen={this.state.deleteOpen}
        onConfirm={() => this.setState({ deleteOpen: false }, this.delete)}
        onCancel={() => this.setState({ deleteOpen: false })}
        alertBody="¿Estás seguro que quieres remover este local? Esto no modificará los partidos que ya tengan espacios asignados"
        confirmButtonTitle="Si, estoy seguro"
        cancelButtonTitle="Volver atrás"
      />
    );
  }

  render() {
    const { classes } = this.props;

    if (!this.state.ready) {
      return (
        <Grid item container justifyContent="center" style={{ marginTop: 30 }}>
          <CircularProgress size={24}></CircularProgress>
        </Grid>
      );
    }

    return (
      <>
        <Grid container className={classes.root}>
          <Grid
            className={classes.container}
            container
            item
            xs={12}
            justifyContent="space-between"
            spacing={8}
          >
            <Grid item container justifyContent="space-between" xs={12}>
              <Typography variant="h1" className={classes.title}>
                Locales
              </Typography>
              <Button
                onClick={() => {
                  this.setState({ openModal: true });
                }}
                className={`cta ${classes.createButton}`}
              >
                Agregar
              </Button>
            </Grid>
            {this.state.establishments.map((establishment) => (
              <Grid item xs={6} key={establishment.id}>
                <Card>
                  <CardMedia
                    component="img"
                    height="140"
                    image={establishment.full_banner_url}
                    alt={establishment.public_name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h3" component="div">
                      {establishment.public_name}
                    </Typography>
                    <Typography variant="caption">
                      {establishment.address}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() =>
                        this.setState({
                          toDeleteEstablishment: establishment,
                          deleteOpen: true,
                        })
                      }
                    >
                      Remover
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
            {this.state.establishments.length === 0 && (
              <Grid
                container
                justifyContent="center"
                style={{ paddingTop: 20 }}
              >
                <Typography variant="caption">
                  Agrega locales a tu evento para utilizar sus canchas en tus
                  partidos
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {this.renderConfirmDeleteDialog()}
        {this.renderAddDialog()}
      </>
    );
  }
}

export default withStyles(styles)(CompetitionEstablishments);
