import React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";

export default function OrganizationAutocomplete(props) {
  const [value, setValue] = React.useState(props.defaultValue || null);
  const [open, toggleOpen] = React.useState(false);
  let options = props.organizations;

  const onOrganizationSelected = props.onOrganizationSelected;

  //sort organizations by ID
  options.sort((a, b) => {
    return a.id - b.id;
  });

  const handleClose = () => {
    setDialogValue({
      name: "",
      description: "",
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
    description: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const value = {
      name: dialogValue.name,
      description: dialogValue.description,
    };
    setValue(value);
    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string" || (newValue && newValue.addNew)) {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue.addNew ? "" : newValue,
                description: "",
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue,
              description: "",
            });
          } else {
            setValue(newValue);
            onOrganizationSelected(newValue);
          }
        }}
        id="free-solo-dialog-demo"
        options={options}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        groupBy={(option) => option.group}
        selectOnFocus
        handleHomeEndKeys
        renderOption={(props, option, { selected }) => <li {...props}>{option.name}</li>}
        style={{ width: "auto" }}
        freeSolo
        renderInput={(params) => <TextField {...params} label="Organización" variant="outlined" />}
      />
    </React.Fragment>
  );
}
