import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  itemCard: {
    backgroundColor: theme.palette.primary.secondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%", // Ensures equal height for all cards
  },
  description: {
    maxHeight: "100px", // Limit the height of the description
    overflowY: "auto", // Makes it scrollable if content overflows
  },
  cardContent: {
    flexGrow: 1, // Ensures content takes up available space
  },
}));

const SubscriptionModal = ({
  open,
  onClose,
  subscriptionPlans,
  onSubscribe,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Typography style={{ textAlign: "center", marginTop: 15 }} variant="h2">
        Elegí tu plan
      </Typography>
      <DialogContent style={{ paddingTop: "24px", paddingBottom: "24px" }}>
        <Grid container spacing={4} justifyContent="center">
          {subscriptionPlans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card variant="elevation" className={classes.itemCard}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                    align="center"
                  >
                    {plan.public_name}
                  </Typography>
                  <Typography
                    variant="h2"
                    component="p"
                    color="textSecondary"
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    {plan.currency}{" "}
                    {parseInt(plan.price)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.description}
                    style={{ marginBottom: "16px", marginTop: "10px" }}
                  >
                    {plan.public_description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      onClose();
                      onSubscribe(plan.id);
                    }}
                  >
                    {plan.service.name === "Free" ? "Seleccionar" : "Contratar"}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionModal;
