import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
import EventSystemForm from "./EventSystemForm";

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  formContainer: {
    padding: theme.spacing(4),
  },
});

const CreateClassificationEvent = (props) => {
  const { classes, location } = props;
  const eventSystem = location.state?.eventSystem;

  return (
    <Grid container className={classes.container} xs={4}>
      <Paper className={classes.formContainer}>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.title}>
            {eventSystem ? "Editar" : "Nuevo"} evento de clasificación
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <EventSystemForm
            {...props}
            eventSystem={eventSystem}
            onSubmitResponse={() => {
              props.history.push("/classification-events");
            }}
          />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(CreateClassificationEvent);
