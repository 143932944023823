/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import { TextField, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const punctuationStages = require("../../../constants/punctuationsStages.json");

const styles = (theme) => ({
  container: {
    //padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    //make it expand horizontally
    width: "100%",
  },
  createButton: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    marginBottom: theme.spacing(2),
    fontSize: 14,
    fontWeight: 600,
    textTransform: "none",
  },
  cell: {
    width: "auto",
  },
  tournamentTitleRanking: {
    fontWeight: 300,
    fontSize: 16,
    color: theme.palette.primary.light,
    marginBottom: theme.spacing(1),
  },
  tournamentTitle: {
    fontWeight: 300,
    fontSize: 16,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
});

const PunctuationSystemTable = ({ table, classes, onChange }) => {
  const emptyTable = {};
  punctuationStages.stages.forEach((punctuationStage) => {
    emptyTable[punctuationStage.slug] = 0;
  });

  const [punctuationSystemTable, setPunctuationSystemTable] = useState(table || emptyTable);

  const onValueChange = (slug, value) => {
    setPunctuationSystemTable({
      ...punctuationSystemTable,
      [slug]: value ? parseInt(value, 10) : 0,
    });
  };

  useEffect(() => {
    console.log("punctuationSystemTable", punctuationSystemTable);
    onChange(punctuationSystemTable);
  }, [punctuationSystemTable]);

  // create a table row for each punctuation stage in the punctuationStages: it will contain the punctuation stage label as name and the punctuation stage value which can be changed by the user, value should be numeric
  // store the punctuation stage value in the punctuationSystemTable state. punctuationSystemTable can have default values

  const renderTableRows = () => {
    return punctuationStages.stages.map((punctuationStage, index) => {
      //if in the table there is a value for the current punctuation stage, use it, otherwise use 0
      const value = punctuationSystemTable[punctuationStage.slug];

      return (
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            {punctuationStage.label}
          </TableCell>
          <TableCell align="center">
            <TextField
              variant="standard"
              type="number"
              value={value}
              onChange={(e) => onValueChange(punctuationStage.slug, e.target.value)}
              required />
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.gridContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Etapa</TableCell>
              <TableCell align="center">Puntos por llegar a la etapa</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PunctuationSystemTable);
