import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

export default function AlertDialog({
  buttonTitle = "Open alert dialog",
  alertTitle = null,
  alertBody = "¿Estás seguro de eliminar este torneo?",
  confirmButtonTitle = "Confirm",
  cancelButtonTitle = "Cancel",
  isOpen = false,
  hideButton = false,
  onConfirm = () => {},
  onCancel = () => {},
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (confirm) => {
    setOpen(false);
    if (confirm) {
      onConfirm();
    } else {
      onCancel();
    }
  };

  return (
    <div>
      {!hideButton && (
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {buttonTitle}
        </Button>
      )}
      <Dialog
        open={hideButton ? isOpen : open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {alertTitle && <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{alertBody}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            {cancelButtonTitle}
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            {confirmButtonTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
