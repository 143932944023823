import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import performRequest from "network/perform-request";
import { withSnackbar } from "notistack";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  classificationEventSystemCreateRoute,
  classificationEventSystemShowRoute,
  classificationEventSystemUpdateRoute,
  // classificationEventSystemUpdateRoute,
  classificationsIndexRoute,
  getUserOrganizationsRoute,
} from "network/api-routes";

const styles = (theme) => {};

const EventSystemForm = (props) => {
  const { userContext, eventSystem } = props;
  const { user } = userContext;
  const evalConditions = eventSystem ? Object.values(eventSystem.eval_conditions)[0] : null;

  const [organizationId, setOrganizationId] = useState("");
  const [classificationId, setClassificationId] = useState("");
  const [wasChampion, setWasChampion] = useState(evalConditions?.was_champion || "");
  const [totalParticipations, setTotalParticipations] = useState(evalConditions?.total_participations || "");
  const [organizations, setOrganizations] = useState([]);
  const [organizationsReady, setOrganizationsReady] = useState(false);
  const [classifications, setClassifications] = useState([]);
  const [classificationsReady, setClassificationsReady] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const fetchOrganizations = useCallback(() => {
    setOrganizationsReady(false);
    performRequest("GET", getUserOrganizationsRoute, {}, true, user)
      .then((response) => {
        const organizations = response.data;
        setOrganizations(organizations);
        setOrganizationsReady(true);
      })
      .catch((error) => console.error(error));
  }, [user]);

  const fetchClassifications = useCallback(
    (organization_id) => {
      setClassificationId(null);
      setClassificationsReady(false);
      performRequest("POST", classificationsIndexRoute, { organization_id }, true, user)
        .then((response) => {
          const classifications = response.data;
          setClassifications(classifications);
          if (eventSystem) {
            setClassificationId(eventSystem.classification_id);
          }
          setClassificationsReady(true);
        })
        .catch((error) => console.error(error));
    },
    [eventSystem, user]
  );

  const fetchClassificationEventSystem = useCallback(
    (eventSystem) => {
      performRequest(
        "POST",
        classificationEventSystemShowRoute,
        {
          organization_id: eventSystem.organization_id,
          classification_events_systems_uuid: eventSystem.uuid_,
        },
        true,
        user
      )
        .then((response) => {
          setOrganizationId(response.data.organization_id);
          fetchClassifications(response.data.organization_id);
        })
        .catch((error) => console.error(error));
    },
    [fetchClassifications, user]
  );

  useEffect(() => {
    fetchOrganizations();
    if (eventSystem) {
      fetchClassificationEventSystem(eventSystem);
    }
  }, [eventSystem, fetchClassificationEventSystem, fetchOrganizations]);

  const handleSubmit = () => {
    /*
    const evalConditions = {
      ascentOfClassification: {
        was_champion: wasChampion,
        total_participations: totalParticipations,
      },
    };
    */

    const evalConditions = {
      suggestPromotion: {
        was_champion: wasChampion,
        total_participations: totalParticipations,
      },
    };

    const requestBody = {
      organization_id: organizationId,
      classification_id: classificationId,
      events: JSON.stringify(evalConditions),
    };

    if (eventSystem) {
      requestBody.classification_events_systems_uuid = eventSystem.uuid_;
    }

    const formData = new FormData();

    Object.entries(requestBody).forEach(([key, value]) => {
      formData.append(key, value);
    });

    performRequest(
      "POST",
      eventSystem ? classificationEventSystemUpdateRoute : classificationEventSystemCreateRoute,
      formData,
      true,
      user,
      null,
      { "Content-Type": "multipart/form-data" }
    )
      .then((response) => {
        const newPunctuationSystem = response.data;
        setSubmitting(false);
        if (eventSystem) {
          props.enqueueSnackbar("Sistema de evento actualizado", {
            variant: "success",
          });
        }
        props.onSubmitResponse(newPunctuationSystem);
      })
      .catch((error) => {
        setSubmitting(false);
        props.enqueueSnackbar(error?.response?.data?.message || "Ha ocurrido un error. Revisa los datos", {
          variant: "error",
        });
        console.error(error);
      });
  };

  return (
    <form>
      <TextField label="Tipo de evento" value={"Ascenso"} disabled margin="normal" variant="outlined" fullWidth />
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel id="organizationIdLabel">Organización</InputLabel>
        <Select
          variant="standard"
          labelId="organizationIdLabel"
          id="organizationId"
          value={organizationId}
          onChange={(e) => {
            setOrganizationId(e.target.value);
            fetchClassifications(e.target.value);
          }}
          label="Organization ID">
          {organizationsReady &&
            organizations.map((org) => (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel id="organizationIdLabel">Ranking</InputLabel>
        <Select
          variant="standard"
          labelId="classificationIdLabel"
          id="classificationId"
          value={classificationId}
          disabled={!classificationsReady}
          onChange={(e) => setClassificationId(e.target.value)}
          label="Classification ID">
          {classificationsReady &&
            classifications.map((clas) => (
              <MenuItem key={clas.id} value={clas.id}>
                {clas.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        label="¿Cuantos torneos debe ganar el jugador?"
        value={wasChampion}
        onChange={(e) => {
          const numericValue = e.target.value;
          // Update the state only if the value is a positive integer
          if (!isNaN(numericValue) && numericValue >= 0) {
            setWasChampion(numericValue);
          }
        }}
        margin="normal"
        variant="outlined"
        fullWidth
        inputProps={{
          type: "number",
          min: 0,
        }}
      />
      <TextField
        label="¿Cuantas veces debe participar el jugador?"
        value={totalParticipations}
        onChange={(e) => {
          const numericValue = e.target.value;
          // Update the state only if the value is a positive integer
          if (!isNaN(numericValue) && numericValue >= 0) {
            setTotalParticipations(numericValue);
          }
        }}
        margin="normal"
        variant="outlined"
        fullWidth
        inputProps={{
          type: "number",
          min: 0,
        }}
      />
      <Grid xs={12} container justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={submitting}>
          {eventSystem ? "Guardar cambios" : "Crear evento"}
        </Button>
      </Grid>
    </form>
  );
};

export default withSnackbar(withStyles(styles)(EventSystemForm));
