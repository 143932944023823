import React, { Component } from "react";
import AppRouter from "./components/AppRouter";
import UserContext from "./context/UserContext";
import { getUser } from "./network/user";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { Helmet } from "react-helmet";

import axios from "axios";
axios.defaults.withCredentials = true;

class App extends Component {
  constructor(props) {
    super(props);
    this.changeUser = (user, callback) => {
      const newState = {
        user: user,
        loggedIn: user ? true : false,
      };
      localStorage.setItem("isLoggedIn", true);
      this.setState(newState);
    };

    this.state = {
      user: null,
      changeUser: this.changeUser,
    };
  }

  componentDidMount() {
    let user = null;
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
      // Fetch User
      getUser()
        .then((response) => {
          user = response.data.data;
          this.setState((state) => {
            window.Intercom("boot", {
              app_id: "zfp1tuwi",
              name: user.full_name, // Full name
              email: user.email, // Email address
              alignment: "right",
              horizontal_padding: 30,
              vertical_padding: 30,
            });
            return { ...state, user: user, userReady: true, loggedIn: true };
          });
        })
        .catch((error) => {
          localStorage.setItem("isLoggedIn", false);
          this.setState((state) => {
            return { user: null, userReady: true, loggedIn: false };
          });
        });
    } else {
      this.setState((state) => {
        return { user: null, userReady: true, loggedIn: false };
      });
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="viewport" content="width=1024" />
        </Helmet>

        <UserContext.Provider value={this.state}>
          <AppRouter {...this.props} />
        </UserContext.Provider>
      </div>
    );
  }
}
export default DragDropContext(HTML5Backend)(App);
